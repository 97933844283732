import React from "react";
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import GetSavedOrderRequest from "./api/GetSavedOrderRequest";
import GetProductAddonsRequest from './api/GetProductAddonsRequest.js';
import Progress from './Progress.js';
import ProgressDialog from './ProgressDialog.js';
import ErrorAlertDialog from './ErrorAlertDialog.js';
import ErrorDisplay from './ErrorDisplay.js';
import formatCurrencyString from './utils/formatCurrencyString.js';
import Quantity, { Units } from './model/Quantity.js';
import RequestExecutor from './api/RequestExecutor.js';
import UpdateOrderProductAddonsRequest from './api/UpdateOrderProductAddonsRequest.js';

class PurchaseAddonsStep extends React.Component {

  onCheckout;
  closeErrorDialog;
  fetchAddons;

  constructor(props) {
    super(props);
    this.state = { showingError: false, errorTitle: "", errorMessage: "", updating: false, savedOrder: null, fetchError: null, productAddons: null, selections: {} };
    this.onCheckout = this.onCheckout.bind(this);
    this.closeErrorDialog = this.closeErrorDialog.bind(this);
    this.fetchAddons = this.fetchAddons.bind(this);
  }

  componentDidMount() {
    this.fetchAddons();
    this.props.showFooter(false);
  }

  fetchAddons() {
    this.props.authorize()
      .then(() => GetSavedOrderRequest.execute())
      .then(order => {
        // TODO: what if the session is corrupt?
        this.setState({ savedOrder: order });
        return order;
      })
      .then((order) => GetProductAddonsRequest.execute(order.product.identifier))
      .then((productAddons) => {
        this.setState({ productAddons: productAddons });
      })
      .catch(error => {
        console.log(error);
        this.setState({ fetchError: error });
      });
  }

  updateQuantityForAddon(addon, increment) {
    const selections = this.state.selections;
    const selection = selections[addon.identifier];
    const units = Units.fromRequestValue(addon.units[0]);
    const quantityValue = Math.max(((selection) ? selection.quantity.value.value : 0) + increment, 0);
    if (quantityValue > 0) {
      selections[addon.identifier] = { quantity: Quantity.inUnits(units, quantityValue) };
    } else {
      delete selections[addon.identifier];
    }
    this.setState({ selections: selections });
  }

  onCheckout() {
    const selections = this.state.selections;
    if (Object.keys(selections).length > 0) {
      this.setState( { updating: true} );
      UpdateOrderProductAddonsRequest.execute(selections)
        .then(() => this.props.history.push(`/checkout/`))
        .catch((error) => {          
          console.log(error);
          this.setState({ updating: false, showingError: true, errorTitle: "Something went wrong.", errorMessage: RequestExecutor.errorMessageForServiceError(error) || "Please try again." });
        });
    } else {
      this.props.history.push(`/checkout/`);
    }
  }

  closeErrorDialog() {
    this.setState({ showingError: false, errorTitle: "", errorMessage: "" });
  }

  render() {
    const savedOrder = this.state.savedOrder;
    const fetchError = this.state.fetchError;
    const productAddons = this.state.productAddons;
    const selections = this.state.selections;
    if (savedOrder === null || productAddons == null) {
      return (
        <div className="purchaseAddonsLoading has-text-centered">
          {(fetchError) ? <ErrorDisplay errorAction={this.fetchAddons} /> : <Progress />}
        </div>
      );
    }
    return (
      <Section id="purchaseAddonsSection">
        <ErrorAlertDialog open={this.state.showingError} title={this.state.errorTitle} message={this.state.errorMessage} onClose={this.closeErrorDialog} />
        <ProgressDialog open={this.state.updating} title="Updating Order…" />
        <Container id="purchaseAddonsContainer">
          <h1>Get Everything You Need</h1>
          <h2>Buy now and get these items delivered free with your {savedOrder.product.display_name}.</h2>
          <div className="purchaseAddonsCheckout" onClick={this.onCheckout}>Continue to Checkout ›</div>
          <div className="purchaseAddonContainer">
            {productAddons.map(addon => {
              const selection = selections[addon.identifier];
              const quantityValue = (selection) ? selection.quantity.value.value : 0;
              return (
                <div className="purchaseAddonItemContainer" key={addon.identifier}>
                  <div className="purchaseAddonImage">
                    <img alt={addon.display_name} src={`${RequestExecutor.baseURL + '/' + addon.thumbnail_image_url}`} />
                  </div>
                  <div className="purchaseAddonContent">
                    <h1>{addon.display_name}</h1>
                    <h2><span className="purchaseAddonUnitPrice">{formatCurrencyString(addon.unit_price_in_dollars)}</span> <span className="purchaseAddonUnits">{Units.fromRequestValue(addon.units[0]).perUnitDisplayName()}</span></h2>
                    <div className="purchaseAddonSelector">
                      <span className="purchaseAddonPlus" onClick={() => this.updateQuantityForAddon(addon, 1)}>+</span>
                      <span className="purchaseAddonCount">{quantityValue}</span>
                      <span className="purchaseAddonMinus" onClick={() => this.updateQuantityForAddon(addon, -1)}>-</span>
                    </div>
                  </div>
                </div>);
            })}
          </div>
        </Container>
      </Section>
    );
  }
}

export default PurchaseAddonsStep;