import React from 'react';

const isDevelopment = '_self' in React.createElement('div');

class Environment {
    static get isDevelopment() {
        return isDevelopment;
    }
}

export default Environment;