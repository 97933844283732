import RequestExecutor from './RequestExecutor.js';

const GET_ORDER_ENDPOINT = "/orders/";

class GetOrderRequest {
    static execute(orderIdentifier) {
        return RequestExecutor.executeGet(`${GET_ORDER_ENDPOINT}${orderIdentifier}`);
    }
}

export default GetOrderRequest;