import RequestExecutor from './RequestExecutor.js';

const PRODUCT_IDENTIFIER_SLUG = ":productIdentifier";
const SELLER_SHORT_NAME_SLUG = ":sellerShortName";
const GET_STORE_PRODUCT_ENDPOINT = `/products/seller/${SELLER_SHORT_NAME_SLUG}/${PRODUCT_IDENTIFIER_SLUG}`;

class GetStoreProductRequest {
    static execute(sellerShortName, productIdentifierOrSlug) {
        const query = { includes_delivery_pricing: true };
        const endpoint = GET_STORE_PRODUCT_ENDPOINT.replace(SELLER_SHORT_NAME_SLUG, sellerShortName).replace(PRODUCT_IDENTIFIER_SLUG, productIdentifierOrSlug);
        return RequestExecutor.executeGet(endpoint, RequestExecutor.appendStoreContext(query));
    }
}

export default GetStoreProductRequest;