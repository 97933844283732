import React from 'react';
import ProductPurchaseStep from './ProductPurchaseStep.js';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';
import { parsePhoneNumber } from 'libphonenumber-js';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';

const SELECTED_STEP_DESCRIPTION = "Site Contact";
const STEP_DESCRIPTION = "Who takes Delivery?";

class SiteContactPurchaseStep extends React.Component {
    onNameTextFieldChange;
    onPhoneNumberTextFieldChange;
    didChooseSiteContact;
    didSelectSiteContactChip;

    constructor(props) {
        super(props);
        this.state = { nameValue: "", phoneValue: "" };
        this.onNameTextFieldChange = this.onNameTextFieldChange.bind(this);
        this.onPhoneNumberTextFieldChange = this.onPhoneNumberTextFieldChange.bind(this);
        this.didChooseSiteContact = this.didChooseSiteContact.bind(this);
        this.didSelectSiteContactChip = this.didSelectSiteContactChip.bind(this);
    }

    onNameTextFieldChange(event) {
        this.setState({ nameValue: event.target.value });
    }

    onPhoneNumberTextFieldChange(value) {
        this.setState({ phoneValue: value });
    }

    _isValidPhoneNumber(phoneValue) {
        if (!phoneValue || phoneValue === "") {
            return false;
        }
        return ((phoneValue) => {
            try {
                return parsePhoneNumber(phoneValue, "US").isValid();
            } catch (error) {
                return false;
            }
        })(phoneValue);
    }

    didChooseSiteContact() {
        this.props.onChooseSiteContact({ name: this.state.nameValue, phoneNumber: this.state.phoneValue });
    }

    didSelectSiteContactChip() {
        this.props.onEditSiteContact();
    }

    render() {
        const nameValue = this.state.nameValue;
        const phoneValue = this.state.phoneValue;
        const siteContact = this.props.siteContact;
        const stepNumber = this.props.stepNumber;
        const stepDescription = (siteContact) ? SELECTED_STEP_DESCRIPTION : STEP_DESCRIPTION;
        const canSave = nameValue && nameValue !== "" && this._isValidPhoneNumber(phoneValue);
        return (
            <ProductPurchaseStep completed={siteContact || this.props.completed} stepNumber={stepNumber} stepDescription={stepDescription}>
                {(this.props.completed) ? (<Chip icon={<PersonIcon />} onClick={this.didSelectSiteContactChip} label={`${nameValue} ${phoneValue}`} />) :
                    (<div id="siteContactInputContainer">
                        <div className="nameInput">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Name"
                                type="search"
                                autoComplete="off"
                                onChange={this.onNameTextFieldChange}
                                value={nameValue}
                            />
                        </div>
                        <div className="phoneInput">
                            <MuiPhoneNumber
                                defaultCountry={'us'}
                                onlyCountries={['us']}
                                disableDropdown={true}
                                margin="dense"
                                id="phone"
                                label="Phone"
                                type="phone"
                                autoComplete="off"
                                disableCountryCode={true}
                                onChange={this.onPhoneNumberTextFieldChange}
                                value={phoneValue}
                            />
                        </div>
                        {(canSave) ?
                            (<Button id="chooseDeliveryTimeButton" variant="contained" color="primary" onClick={this.didChooseSiteContact}>Done</Button>) :
                            (<Button disabled id="chooseDeliveryTimeButton" variant="contained" color="secondary" onClick={this.didChooseSiteContact}>Done</Button>)
                        }

                    </div>)}
            </ProductPurchaseStep>
        );
    }
}

export default SiteContactPurchaseStep;