import React from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

class QuantitySelector extends React.Component {
    render() {
        const selectedQuantity = this.props.selectedQuantity;
        const selectedUnits = this.props.selectedUnits;
        const selectableUnits = this.props.selectableUnits;
        const selectableQuantitiesByUnits = this.props.selectableQuantitiesByUnits;
        return (<div>
            <div className="quantityInputContainer">
                <form className="quantityForm" autoComplete="off">
                    <FormControl>
                        <InputLabel htmlFor="quantityNativeHelper">Amount</InputLabel>
                        <NativeSelect
                            value={(selectedQuantity !== null) ? `${selectedQuantity.units.identifier}-${selectedQuantity.value.value}` : ""}
                            onChange={this.props.didUpdateSelectedQuantity}
                            input={<Input name="quantity" id="quantityNativeHelper" />}
                        >
                            {
                                (selectedUnits !== null && selectableQuantitiesByUnits[selectedUnits.identifier]) ? (() => {
                                    const quantities = selectableQuantitiesByUnits[selectedUnits.identifier];
                                    return quantities.map(q => {
                                        const key = `${q.units.identifier}-${q.value.value}`;
                                        return (
                                            <option key={key} value={key} >{q.value.value}</option>
                                        );
                                    });
                                })() : null
                            }
                        </NativeSelect>
                    </FormControl>
                </form>
                <form className="unitsForm" autoComplete="off">
                    <FormControl>
                        <InputLabel htmlFor="unitsNativeHelper">Units</InputLabel>
                        <NativeSelect
                            value={(selectedUnits !== null) ? selectedUnits.identifier : ""}
                            onChange={this.props.didUpdateSelectedUnits}
                            input={<Input name="units" id="unitsNativeHelper" />}
                        >
                            {
                                selectableUnits.map((u) =>
                                    (<option key={u.identifier} value={u.identifier} >{u.displayName.plural}</option>)
                                )
                            }
                        </NativeSelect>
                    </FormControl>
                </form>
            </div>
            {(selectedUnits && selectedQuantity) ? <Button id="chooseQuantityButton" variant="contained" color="primary" onClick={this.props.didChooseQuantity}>Done</Button> : null}
        </div>);
    }
}

export default QuantitySelector;