import RequestExecutor from './RequestExecutor.js';

const CHARGE_ORDER_ENDPOINT = "/orders/saved/charge";

class ChargeOrderRequest {
    static execute(invoiceIdentifier, token) {
        let data = { invoice_id: invoiceIdentifier, token: token };
        return RequestExecutor.executePost(CHARGE_ORDER_ENDPOINT, data);
    }
}

export default ChargeOrderRequest;