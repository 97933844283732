import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Progress from './Progress.js';

class ProgressDialog extends React.Component {
    render() {
        const open = this.props.open;
        const title = this.props.title;
        return (
            <Dialog
                open={open}
                aria-labelledby="progress-dialog-title"
            >
                <DialogTitle id="progress-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <div className="has-text-centered">
                        <Progress />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default ProgressDialog;