import RequestExecutor from './RequestExecutor.js';

const STORE_NAME_SLUG = ":store_name"
const GET_STORE_CONTEXT_ENDPOINT = `/stores/${STORE_NAME_SLUG}/context`;

class GetStoreContextRequest {
    static execute(storeName) {
        const endpoint = GET_STORE_CONTEXT_ENDPOINT.replace(STORE_NAME_SLUG, storeName);
        return RequestExecutor.executeGet(endpoint);
    }
}

export default GetStoreContextRequest;