import RequestExecutor from './RequestExecutor.js';

const PRODUCT_IDENTIFIER_SLUG = ":productIdentifier"
const GET_PRODUCT_ADDONS_ENDPOINT = `/products/${PRODUCT_IDENTIFIER_SLUG}/addons`;

class GetProductAddonsRequest {
    static execute(productIdentifier) {
        const endpoint = GET_PRODUCT_ADDONS_ENDPOINT.replace(PRODUCT_IDENTIFIER_SLUG, productIdentifier);
        return RequestExecutor.executeGet(endpoint);
    }
}

export default GetProductAddonsRequest;