import React from 'react';
import GetMapTokenRequest from './api/GetMapTokenRequest.js';

const REGION_SPAN = 0.5
const ZOOM_REGION_SPAN = 0.01
const DEGREES_IN_SQUARE_MILES = 0.69;

class Map extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.map = null;
    }

    componentDidMount() {
        this.map = new window.mapkit.Map("map");
        this._updateMapLocation();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location !== this.props.location || prevProps.annotationAddress !== this.props.annotationAddress || prevProps.zoom !== this.props.zoom) {
            this._updateMapLocation();
        }
    }

    render() {
        return <div id="map" />;
    }

    static initialize() {
        window.mapkit.init({
            authorizationCallback: done => {
                GetMapTokenRequest.execute()
                    .then(data => {
                        done(data);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        });
        window.mapkit.addEventListener("configuration-change", (function (event) {
            switch (event.status) {
                case "Initialized":
                    console.log("MapKit initialized.");
                    break;
                case "Refreshed":
                    console.log("MapKit refreshed.")
                    break;
                default:
                    break;
            }
        }));
        window.mapkit.addEventListener("error", (function (error) {
            console.log(`MapKit error: ${error.status}`);
        }));
    }

    _updateMapLocation() {
        const location = this.props.location;
        const zoom = this.props.zoom;
        const regionSpan = (zoom ? ZOOM_REGION_SPAN : REGION_SPAN) / DEGREES_IN_SQUARE_MILES;
        const mapkit = window.mapkit;
        const map = this.map;
        const region = new mapkit.CoordinateRegion(
            new mapkit.Coordinate(location.latitude, location.longitude),
            new mapkit.CoordinateSpan(regionSpan, regionSpan)
        );
        map.setRegionAnimated(region, true);
        map.showsCompass = mapkit.FeatureVisibility.Hidden;
        const annotationAddress = this.props.annotationAddress;
        if (annotationAddress) {
            const annotationLocation = new mapkit.Coordinate(annotationAddress.location.latitude, annotationAddress.location.longitude);
            const annotation = new mapkit.MarkerAnnotation(annotationLocation, {
                title: annotationAddress.street_address,
                subtitle: "Job Site",
                color: "#000000",
                displayPriority: 1000
            });
            map.addAnnotation(annotation);
        } else if (map.annotations && map.annotations.length > 0) {
            map.removeAnnotations(map.annotations);
        }
    }
}

export default Map;