import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactMarkdown from 'react-markdown';
import Progress from './Progress.js';

class MarkdownDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = { fetchingMarkdown: true, markdown: null }
    }

    componentDidMount() {
        this.props.fetchMarkdown()
            .then(markdown => {
                this.setState({ fetchingMarkdown: false, markdown: markdown });
            })
            .catch(error => {
                console.log(error);
                // TODO: retry?
            })
    }

    render() {
        const open = this.props.open;
        const title = this.props.title;
        const markdown = this.state.markdown;
        const onClose = this.props.onClose;
        return (
            <Dialog
                open={open}
                aria-labelledby="markdown-dialog-title"
                aria-describedby="markdown-dialog-description"
            >
                <DialogTitle id="markdown-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {(markdown) ? <ReactMarkdown source={markdown} renderers={{link: props => {
                        if (props.href.startsWith("http")) {
                            return (<a href={props.href} rel="noopener noreferrer" target="_blank">{props.children}</a>);
                        } else {
                            return (<span>{props.children}</span>);
                        }
                    }}} /> : (<div class="has-text-centered"><Progress /></div>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default MarkdownDialog;