import RequestExecutor from './RequestExecutor.js';

const GET_ADDRESS_ENDPOINT = "/orders/job_sites/get_address";

class GetAddressRequest {
    static execute(search, location, magicKey) {
        const query = { query: search, location: `${location.latitude},${location.longitude}`, magic_key: magicKey };
        return RequestExecutor.executeGet(GET_ADDRESS_ENDPOINT, query);
    }
}

export default GetAddressRequest;