import React from 'react';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import { Quantity } from './model/Quantity.js';

class SynthGrassCutsComponent extends React.Component {
    addSynthGrassCut;
    closeSynthGrassCutsDialog;
    onCutInputChange;
    showSynthGrassCutsDialog;

    constructor(props) {
        super(props);
        this.addSynthGrassCut = this.addSynthGrassCut.bind(this);
        this.closeSynthGrassCutsDialog = this.closeSynthGrassCutsDialog.bind(this);
        this.onCutInputChange = this.onCutInputChange.bind(this);
        this.showSynthGrassCutsDialog = this.showSynthGrassCutsDialog.bind(this);
        this.state = { open: false, cutFieldValue: "" };
    }

    addSynthGrassCut() {
        const cutSize = parseInt(this.state.cutFieldValue);
        if (cutSize > 0) {
            const cuts = this.props.cuts;
            this.props.didSelectSynthGrassCuts(cuts.concat({ size: cutSize }));
            this.setState((state, props) => ({
                cutFieldValue: ""
            }));
        }
    }

    showSynthGrassCutsDialog() {
        this.setState({ open: true });
    }

    closeSynthGrassCutsDialog() {
        this.setState({ cutFieldValue: "", open: false });
    }

    deleteCut(key) {
        this.props.deleteCut(key);
        this.setState({ cutFieldValue: "" });
    }

    onCutInputChange(event) {
        const eventValue = event.target.value;
        if (eventValue === "") {
            this.setState({ cutFieldValue: "" });
            return;
        }
        const value = parseInt(event.target.value);
        if (value > 0) {
            this.setState({ cutFieldValue: value });
        }
    }

    render() {
        const open = this.state.open;
        const cuts = this.props.cuts;
        const cutFieldValue = this.state.cutFieldValue;
        const chipLabel = (cuts.length > 0) ? `Edit ${cuts.length} cuts` : "Select Cuts";
        const isComplete = this.props.selectedQuantity.isGreaterThan(this.props.minimumQuantity) || this.props.selectedQuantity.isEqualTo(this.props.minimumQuantity);
        return (
            <span className="customizeSynthGrassCutsContainer">
                <Dialog
                    open={open}
                    aria-labelledby="synthGrassCutsDialogTitle"
                    aria-describedby="synthGrassCutsDialogDescription"
                >
                    <DialogTitle id="synthGrassCutsDialogTitle">Choose Cuts</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {`Our turf is cut to order. Each cut is ${this.props.cutsUnits.displayName.singular.replace("× ", "")} wide.`}<br/>{`Minimum order is ${this.props.minimumQuantity.toString()}.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogContent>
                        <div id="synthGrassCutInputContainer">
                            <div id="synthGrassCutSizeInput">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="cut"
                                    label="Length (ft.)"
                                    type="number"
                                    autoComplete="off"
                                    onChange={this.onCutInputChange}
                                    value={cutFieldValue}
                                />
                            </div>
                            <Button id="synthGrassCutsAddButton" onClick={this.addSynthGrassCut} color="primary">
                                Add
                            </Button>
                        </div>
                    </DialogContent>
                    <DialogContent>
                        {(cuts.length === 0) ? (<DialogContentText>No cuts</DialogContentText>) :
                            cuts.map((c, key) =>
                                (<div className="synthGrassCut" key={key}>
                                    <Chip avatar={<Avatar>{key + 1}</Avatar>} label={`${Quantity.inUnits(this.props.cutsUnits, c.size).toString()}`} onDelete={() => this.deleteCut(key)} />
                                </div>)
                            )}
                    </DialogContent>
                    <DialogActions>
                        {(isComplete) ?
                        (<Button onClick={this.closeSynthGrassCutsDialog} color="primary">
                            Done
                        </Button>) : (<Button disabled color="primary">
                            Done
                        </Button>)}
                    </DialogActions>
                </Dialog>
                <Chip icon={<SquareFootIcon />} onClick={this.showSynthGrassCutsDialog} label={chipLabel} />
            </span>
        );
    }
}

export default SynthGrassCutsComponent;