// From https://davidwalsh.name/javascript-debounce-function (modified)
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
        var context = this, args = arguments;
        if (args.length > 0 && typeof args[0].persist === 'function') {
            // For event callbacks in React, persist the event argument to
            // allow it to be used asynchronously.
            args[0].persist();
        }
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
export default debounce;