import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import Button from '@material-ui/core/Button';

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    async submit(ev) {
        let { token } = await this.props.stripe.createToken({ name: "Name" });
        if (token) {
            this.props.onSubmitCharge(token);
        }
    }

    render() {
        const width = window.innerWidth;
        let fontSize;
        if (width > 500) {
            fontSize = '24px';
        } else if (width > 350) {
            fontSize = '20px';
        } else {
            fontSize = '17px';
        }
        var elementStyles = {
            base: {
                color: '#212121',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: fontSize,
                fontSmoothing: 'antialiased',

                '::placeholder': {
                    color: 'rgba(0, 0, 0, 0.20)',
                },
                ':-webkit-autofill': {
                    color: '#e39f48',
                }
            },
            invalid: {
                color: '#E25950',

                '::placeholder': {
                    color: '#FFCCA5',
                },
            },
        };
        return (
            <div className="checkoutPaymentForm">
                <div className="cardInput">
                    <CardElement style={elementStyles} />
                </div>
                <Button id="placeOrderButton" variant="contained" color="primary" onClick={this.submit}>Place Order Now</Button>
                <div className="termsContainer">
                    <p>By placing this order, you agree to the <span className="termsLink" onClick={this.props.onSelectTerms}>Rockery Terms of Service</span>.</p>
                </div>
            </div>
        );
    }
}

export default injectStripe(CheckoutForm);