import RequestExecutor from './RequestExecutor.js';

const PRODUCT_IDENTIFIER_SLUG = ':productIdentifier';
const GET_PRODUCT_DELIVERY_OPTIONS_ENDPOINT = `/products/${PRODUCT_IDENTIFIER_SLUG}/delivery_options`;

class GetProductDeliveryOptionsRequest {
    static execute(productIdentifier, address) {
        const endpoint = GET_PRODUCT_DELIVERY_OPTIONS_ENDPOINT.replace(PRODUCT_IDENTIFIER_SLUG, productIdentifier);
        const query = {
            job_site: `${address.location.latitude},${address.location.longitude}`,
            postal_code: address.postal_code
        };
        return RequestExecutor.executeGet(endpoint, query);
    }
}

export default GetProductDeliveryOptionsRequest;