import React from 'react';
import { geolocated } from "react-geolocated";
import debounce from './utils/debounce.js';
import parseLocation from './utils/parseLocation.js';
import Map from './Map.js';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GetAddressSuggestionsRequest from './api/GetAddressSuggestionsRequest.js';
import GetAddressRequest from './api/GetAddressRequest.js';
import Progress from './Progress.js';
import RequestExecutor from './api/RequestExecutor.js';
import Button from '@material-ui/core/Button';

const MAX_SUGGESTIONS = 5;
const MINIMUM_SUGGESTION_LENGTH = 3;

class LocationSuggestion extends React.Component {

    constructor(props) {
        super(props);
        this.didSelectSuggestion = this.didSelectSuggestion.bind(this);
    }

    didSelectSuggestion() {
        const onSelectSuggestion = this.props.onSelectSuggestion;
        onSelectSuggestion(this.props.suggestion);
    }

    render() {
        const suggestion = this.props.suggestion;
        return (
            <div className="locationSuggestion" onClick={this.didSelectSuggestion}>
                <span className="locationSuggestionIcon"><LocationOnIcon /></span><span className="locationSuggestionText">{suggestion.text}</span>
            </div>
        );
    }
}

class ChooseLocationDialog extends React.Component {
    static get defaultUserLocation() {
        return { latitude: 37.9101, longitude: -122.0652 };
    }

    constructor(props) {
        super(props);
        this.state = { fetchingAddress: false, selectedAddress: null, addressFieldValue: "", selectedSuggestion: null, suggestions: [] };
        this.onSelectSuggestion = this.onSelectSuggestion.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onChooseLocation = this.onChooseLocation.bind(this);
        this.onAddressTextFieldChange = this.onAddressTextFieldChange.bind(this);
        this.addressSuggestionsRequest = null;
        this.getAddressRequest = null;
    }

    fetchAddressSuggestions = debounce((typedQuery) => {
        const location = this.props.coords || ChooseLocationDialog.defaultUserLocation;
        if (!typedQuery || typedQuery === "") {
            if (this.state.suggestions.length > 0 || this.state.selectedSuggestion != null || this.state.selectedAddress != null) {
                this.setState({ selectedAddress: null, selectedSuggestion: null, suggestions: [] });
            }
            return;
        }
        if (this.addressSuggestionsRequest) {
            RequestExecutor.cancel(this.addressSuggestionsRequest);
        }
        this.addressSuggestionsRequest = GetAddressSuggestionsRequest.execute(typedQuery, location, MAX_SUGGESTIONS);
        this.addressSuggestionsRequest
            .then(data => {
                this.addressSuggestionsRequest = null;
                this.setState({ suggestions: data });
            })
            .catch(error => {
                this.addressSuggestionsRequest = null;
            });
    }, 500, false);

    onAddressTextFieldChange(event) {
        const value = event.target.value;
        this.setState({ addressFieldValue: value });
        if (value.length === 0 || value.length > MINIMUM_SUGGESTION_LENGTH) {
            this.fetchAddressSuggestions(value);
        }
    }

    onCancel() {
        this._clearState();
        this.props.onCancel();
    }

    onChooseLocation() {
        const selectedAddress = this.state.selectedAddress;
        this._clearState();
        this.props.onChooseLocation(selectedAddress);
    }

    _clearState() {
        if (this.addressSuggestionsRequest) {
            RequestExecutor.cancel(this.addressSuggestionsRequest);
            this.addressSuggestionsRequest = null;
        }
        if (this.getAddressRequest) {
            RequestExecutor.cancel(this.getAddressRequest);
            this.getAddressRequest = null;
        }
        this.setState({ fetchingAddress: false, selectedAddress: null, addressFieldValue: "", selectedSuggestion: null, suggestions: [] });
    }

    onSelectSuggestion(suggestion) {
        this.setState({ addressFieldValue: suggestion.text, selectedSuggestion: suggestion, suggestions: [] });
        this.updateSelectedAddress(suggestion);
    }

    updateSelectedAddress(suggestion) {
        const query = suggestion.text;
        const location = this.props.coords || ChooseLocationDialog.defaultUserLocation;
        const magicKey = suggestion.magic_key;
        this.setState({ fetchingAddress: true });
        if (this.getAddressRequest) {
            RequestExecutor.cancel(this.getAddressRequest);
        }
        this.getAddressRequest = GetAddressRequest.execute(query, location, magicKey)
        this.getAddressRequest
            .then(data => {
                this.getAddressRequest = null;
                // TODO: handle empty results.
                const selectedAddress = data[0];
                selectedAddress.location = parseLocation(selectedAddress.location);
                this.setState({ fetchingAddress: false, selectedAddress: selectedAddress });
            })
            .catch(error => {
                this.getAddressRequest = null;
                this.setState({ fetchingAddress: false, selectedAddress: null });
            });
    }

    render() {
        const fetchingAddress = this.state.fetchingAddress;
        const selectedAddress = this.state.selectedAddress;
        const addressFieldValue = this.state.addressFieldValue;
        const open = this.props.open;
        const location = (selectedAddress) ? selectedAddress.location : (this.props.coords || ChooseLocationDialog.defaultUserLocation);
        const suggestions = this.state.suggestions;
        return (
            <Dialog id="chooseLocationDialog" fullScreen open={open} onClose={this.onCancel} aria-labelledby="formDialogTitle">
                <Toolbar>
                    <Typography variant="h6">
                        Find your Job Site
                     </Typography>
                    <IconButton edge="end" color="inherit" onClick={this.onCancel} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="address"
                        label="Address"
                        type="search"
                        autoComplete="off"
                        fullWidth
                        onChange={this.onAddressTextFieldChange}
                        value={addressFieldValue}
                    />
                    <div className="mapContainer">
                        <Map location={location} annotationAddress={selectedAddress} zoom={selectedAddress !== null}></Map>
                        <div className="locationSuggestions">
                            {suggestions.map((s) => <LocationSuggestion key={s.magic_key} suggestion={s} onSelectSuggestion={this.onSelectSuggestion} />)}
                        </div>
                        {(selectedAddress && !fetchingAddress) ? (<div className="chooseLocationContainer has-text-centered"><Button variant="contained" color="primary" onClick={this.onChooseLocation}>Choose this Location</Button></div>) : null}
                        {(fetchingAddress) ? (<div className="fetchingAddressProgressOverlay"/>) : null}
                        {(fetchingAddress) ? (<div className="fetchingAddressProgressElement"><div className="has-text-centered"><Progress/></div></div>) : null}
                    </div>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        );
    }
}

export default geolocated({
    suppressLocationOnMount: true,
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000
})(ChooseLocationDialog);