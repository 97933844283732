import React from 'react';
import Container from 'react-bulma-components/lib/components/container';
import Navbar from 'react-bulma-components/lib/components/navbar';
import { Link } from 'react-router-dom';

class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.toggleNavigation = this.toggleNavigation.bind(this);
    }

    toggleNavigation() {
        const menu = document.querySelector('.navbar-menu');
        const burger = document.querySelector('.navbar-burger');
        [menu, burger].forEach(e => e.classList.toggle('is-active'));
    }

    render() {
        const isLoadingAuthentication = this.props.isLoadingAuthentication;
        const isAuthenticated = this.props.isAuthenticated;
        return (
            <Navbar id="navigationBar">
                <Container fluid>
                    <Navbar.Brand>
                        <Link to="/">
                            <div className="navbar-item">
                                <img
                                    id="logo"
                                    src="/assets/images/rockery_logo.svg"
                                    alt="Rockery"
                                />
                                <span className="logotext">
                                    ROCKERY
                                </span>
                            </div>
                        </Link>
                        <a id="burgerDiscloseButton" role="button" className="navbar-burger burger" onClick={this.toggleNavigation} aria-label="menu" aria-expanded="false" data-target="navbarMenu">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </Navbar.Brand>
                    <div id="navbarMenu" className="navbar-menu">
                        <Navbar.Container position="end">
                            <Navbar.Item onClick={this.props.onSignIn}>
                                {(isLoadingAuthentication) ? null : (<div className="menuItem">{isAuthenticated ? "Sign out" : "Sign in"}</div>)}
                            </Navbar.Item>
                        </Navbar.Container>
                    </div>
                </Container>
            </Navbar>
        );
    }
}

export default Navigation;