import React from 'react';
import RequestExecutor from './api/RequestExecutor.js';
import ResponseCache from './api/ResponseCache.js';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import ProductFeatureDetailsDialog from './ProductFeatureDetailsDialog.js';
import ProductFeatureImage from './ProductFeatureImage.js';

class ProductFeatureFeedItem extends React.Component {
    closeProductDetailsDialog;
    openProductDetailsDialog;
    onClickProductDetails;

    constructor(props) {
        super(props);
        this.state = { showingProductDetails: false };
        this.closeProductDetailsDialog = this.closeProductDetailsDialog.bind(this);
        this.openProductDetailsDialog = this.openProductDetailsDialog.bind(this);
        this.onClickProductDetails = this.onClickProductDetails.bind(this);
    }

    render() {
        const feedItem = this.props.feedItem;
        const product = feedItem.product.product;
        // Update the cache now as a clickthrough of this item will require it.
        ResponseCache.setByProductIdentifier(product.identifier, product);
        if (product.slug && product.slug !== "") {
            ResponseCache.setByProductIdentifier(product.slug, product)
        }
        const fragment = this._fragment(product);
        return (
            <Link className="productPurchaseFeatureItem" to={`/products/${fragment}`}>
                <ProductFeatureImage product={product} />
                <div className="productFeatureTitle"><p className="productFeatureNameText">{product.display_name}</p>{(feedItem.badge_text && feedItem.badge_text !== "") ? (<div className="productFeatureBadge"><Chip label={feedItem.badge_text} /></div>) : null}</div>
                <p className="productFeatureDescriptionText">{feedItem.product.description}</p>
                {(feedItem.has_product_details) ?
                    (<span onClick={this.onClickProductDetails}>
                        <p className="productFeatureDisclosureLink" onClick={this.openProductDetailsDialog}>Product Details ›</p>
                        <ProductFeatureDetailsDialog product={product} open={this.state.showingProductDetails} onCloseProductDetailsDialog={this.closeProductDetailsDialog} />
                    </span>) : null}
            </Link>
        );
    }

    onClickProductDetails(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }

    openProductDetailsDialog(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        this.setState({ showingProductDetails: true });
    }

    closeProductDetailsDialog(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        this.setState({ showingProductDetails: false });
    }

    _fragment(product) {
        if (product.slug && product.slug !== "") {
            if (!RequestExecutor.storeContext && product.seller_short_name && product.seller_short_name !== "") {
                return `seller/${product.seller_short_name}/${product.slug}`;
            } else {
                return product.slug;
            }
        }
        return product.identifier;
    }
}

export default ProductFeatureFeedItem;