import RequestExecutor from './RequestExecutor.js';

const GET_LEGAL_ENDPOINT = "/legal";

class GetLegalRequest {
    static execute() {
        const query = {};
        return RequestExecutor.executeGet(GET_LEGAL_ENDPOINT, RequestExecutor.appendStoreContext(query));
    }
}

export default GetLegalRequest;