import React from "react";
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import { Auth0Context } from "./auth/react-auth0-wrapper";
import GetSavedOrderRequest from "./api/GetSavedOrderRequest";
import Progress from './Progress.js';
import ErrorDisplay from './ErrorDisplay.js';
import DeliveryTime from './model/DeliveryTime.js';
import Quantity from './model/Quantity.js';
import formatCurrencyString from './utils/formatCurrencyString.js';
import CheckoutForm from './payment/CheckoutForm.js';
import { Elements } from 'react-stripe-elements';
import GetLegalRequest from './api/GetLegalRequest.js';
import MarkdownDialog from './MarkdownDialog.js';
import ProgressDialog from './ProgressDialog.js';
import ChargeOrderRequest from './api/ChargeOrderRequest.js';
import ErrorAlertDialog from './ErrorAlertDialog.js';
import RequestExecutor from './api/RequestExecutor.js';

class Checkout extends React.Component {
  closeErrorDialog;
  fetchSavedOrder;
  fetchTermsOfService;
  onSelectTerms;
  onSubmitCharge;

  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = { savedOrder: null, orderFetchError: null, showingTerms: false, charging: false, showingError: false, errorTitle: "", errorMessage: "" };
    this.fetchSavedOrder = this.fetchSavedOrder.bind(this);
    this.onSelectTerms = this.onSelectTerms.bind(this);
    this.onCloseTerms = this.onCloseTerms.bind(this);
    this.onSubmitCharge = this.onSubmitCharge.bind(this);
    this.fetchTermsOfService = this.fetchTermsOfService.bind(this);
    this.closeErrorDialog = this.closeErrorDialog.bind(this);
  }

  fetchSavedOrder() {
    this.props.authorize()
      .then(() => GetSavedOrderRequest.execute())
      .then(data => {
        // TODO: what if the session is corrupt?
        this.setState({ savedOrder: data });
      })
      .catch(error => {
        console.log(error);
        this.setState({ orderFetchError: error });
      });
  }

  onSelectTerms() {
    this.setState({ showingTerms: true });
  }

  onSubmitCharge(token) {
    this.setState({ charging: true });
    ChargeOrderRequest.execute(this.state.savedOrder.invoice.identifier, token.id)
      .then(data => {
        this.setState({ charging: false });
        this.props.history.replace(`/confirmation/${data.identifier}`);
      })
      .catch(error => {
        console.log(error);
        this.setState({ charging: false, showingError: true, errorTitle: "Something went wrong.", errorMessage: RequestExecutor.errorMessageForServiceError(error) || "Please try again." });
      });
  }

  closeErrorDialog() {
    this.setState({ showingError: false, errorTitle: "", errorMessage: "" });
  }

  componentDidMount() {
    this.fetchSavedOrder();
    this.props.showFooter(false);
  }

  onCloseTerms() {
    this.setState({ showingTerms: false });
  }

  fetchTermsOfService() {
    return GetLegalRequest.execute()
      .then(data => data.terms_and_conditions)
      .catch(error => { throw error; });
  }

  salesTaxForOrder(order) {
    const addonInvoicesSalesTax = (order.additional_invoices || []).reduce((total, next) => total + next.total_sales_tax_in_dollars, 0);
    return addonInvoicesSalesTax + order.invoice.total_sales_tax_in_dollars;
  }

  subtotalForOrder(order) {
    const addonInvoicesSubtotal = (order.additional_invoices || []).reduce((total, next) => total + next.subtotal_in_dollars, 0);
    return addonInvoicesSubtotal + order.invoice.subtotal_in_dollars;
  }

  grandTotalForOrder(order) {
    const addonInvoicesTotal = (order.additional_invoices || []).reduce((total, next) => total + next.grand_total_in_dollars, 0);
    return addonInvoicesTotal + order.invoice.grand_total_in_dollars;
  }

  render() {
    const savedOrder = this.state.savedOrder;
    const orderFetchError = this.state.orderFetchError;
    if (savedOrder === null) {
      return (
        <div className="checkoutLoading has-text-centered">
          {(orderFetchError) ? <ErrorDisplay errorAction={this.fetchSavedOrder} /> : <Progress />}
        </div>
      );
    }
    const quantity = Quantity.fromRequestValue(savedOrder.invoice.quantity);
    const deliveryChargeDescription = (savedOrder.invoice.delivery_charge_description && savedOrder.invoice.delivery_charge_description !== "") ? savedOrder.invoice.delivery_charge_description : "Delivery";
    return (
      <div>
        <ErrorAlertDialog open={this.state.showingError} title={this.state.errorTitle} message={this.state.errorMessage} onClose={this.closeErrorDialog} />
        <ProgressDialog open={this.state.charging} title="Placing Order…" />
        <MarkdownDialog open={this.state.showingTerms} title="Terms of Service" fetchMarkdown={this.fetchTermsOfService} onClose={this.onCloseTerms} />
        <Section id="checkoutInvoiceSection">
          <Container className="checkoutFormContainer">
            <div className="checkoutInvoiceContainer">
              <div className="checkoutInvoice has-text-centered">
                <h1>Your Order</h1>
                <div className="line" />
                <div className="form">
                  <p>{savedOrder.product.display_name}</p>
                  <p className="row">{savedOrder.invoice.address}</p>
                  <p className="row">{DeliveryTime.displayValueFromDelivery(savedOrder.delivery)}</p>
                  <div className="checkoutInvoicePricedItemsContainer">
                    <p className="row lineItem">{quantity.toString()}</p>
                    <p className="row lineItem price">{formatCurrencyString(savedOrder.invoice.subtotal_in_dollars)}</p>
                  </div>
                  {(savedOrder.additional_invoices || []).map((i, index) => {
                    return (
                      <div>
                        <p className="checkoutInvoiceAddonItem">{savedOrder.additional_products[index].display_name}</p>
                        <div className="checkoutInvoicePricedItemsContainer">
                          <p className="row lineItem subitem">{Quantity.fromRequestValue(i.quantity).toString()}</p>
                          <p className="row lineItem price">{formatCurrencyString(i.subtotal_in_dollars)}</p>
                        </div>
                      </div>);
                  })}
                  {(savedOrder.invoice.delivery_charge_in_dollars && savedOrder.invoice.delivery_charge_in_dollars > 0) ?
                    (<div className="checkoutInvoicePricedItemsContainer">
                      <p className="row lineItem">{deliveryChargeDescription}</p>
                      <p className="row lineItem price">{formatCurrencyString(savedOrder.invoice.delivery_charge_in_dollars)}</p>
                    </div>) : null
                  }
                  <div className="checkoutInvoicePricedItemsContainer">
                    <p className="row lineItem">Sales Tax</p>
                    <p className="row lineItem price">{formatCurrencyString(this.salesTaxForOrder(savedOrder))}</p>
                  </div>
                  <div className="checkoutInvoicePricedItemsContainer">
                    <p className="row lineItem total">Total</p>
                    <p className="row lineItem total price">{formatCurrencyString(this.grandTotalForOrder(savedOrder))}</p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Section>
        <Section id="checkoutPaymentSection">
          <Container className="checkoutFormContainer">
            <div className="checkoutInvoiceContainer paymentContainer">
              <div className="checkoutInvoice has-text-centered">
                <h1>Payment</h1>
                <div className="line" />
                <div className="form">
                  <Elements>
                    <CheckoutForm onSelectTerms={this.onSelectTerms} onSubmitCharge={this.onSubmitCharge} />
                  </Elements>
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    );
  }
}

export default Checkout;