import RequestExecutor from './RequestExecutor.js';

const USERS_ENDPOINT = "/users";

class GuestAuthenticateRequest {
    static execute() {
        return RequestExecutor.executePost(USERS_ENDPOINT);
    }
}

export default GuestAuthenticateRequest;