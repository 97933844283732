import React from 'react';
import Container from 'react-bulma-components/lib/components/container';
import Navbar from 'react-bulma-components/lib/components/navbar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';

class Footer extends React.Component {
    render() {
        return (
            <Navbar id="navigationBar">
                <Container fluid>
                    <Navbar.Brand>
                        <Link to="/">
                            <div className="navbar-item">
                                <img
                                    id="logo"
                                    src="/assets/images/rockery_logo.svg"
                                    alt="Rockery"
                                />
                                <span id="footerLogoText" className="logotext">
                                    ROCKERY®
                                </span>
                            </div>
                        </Link>
                    </Navbar.Brand>
                    <div id="contactContainer">
                        <div className="navbar-item">
                            <div className="contactText"><FontAwesomeIcon icon={faPhone} /> (925) 577-2454</div>
                        </div>
                        <div className="navbar-item">
                            <div className="contactText"><FontAwesomeIcon icon={faEnvelopeSquare} /> getrock@rockeryapp.com</div>
                        </div>
                        <div className="navbar-item">
                            <div className="contactText"><FontAwesomeIcon icon={faHome} /> Walnut Creek, CA</div>
                        </div>
                    </div>
                </Container>
            </Navbar >
        );
    }
}

export default Footer;