import React from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ChooseLocationDialog from './ChooseLocationDialog.js';
import ProductPurchaseStep from './ProductPurchaseStep.js';

const SELECTED_STEP_DESCRIPTION = "Job Site";
const STEP_DESCRIPTION = "Find your Job Site";

class JobSitePurchaseStep extends React.Component {
    didCancelLocation;
    didChooseLocation;
    getInnerRef;
    showSearchDialog;

    constructor(props) {
        super(props);
        this.didCancelLocation = this.didCancelLocation.bind(this);
        this.didChooseLocation = this.didChooseLocation.bind(this);
        this.showSearchDialog = this.showSearchDialog.bind(this);
        this.getInnerRef = this.getInnerRef.bind(this);
        this.state = { jobSiteSearchDialogOpen: false };
    }

    showSearchDialog() {
        this.setState({ jobSiteSearchDialogOpen: true });
        this.getLocation();
    }

    didCancelLocation() {
        this.setState({ jobSiteSearchDialogOpen: false });
    }

    didChooseLocation(address) {
        this.setState({ jobSiteSearchDialogOpen: false });
        this.props.onChooseAddress(address);
    }

    innerRef;
    getInnerRef(ref) {
        this.innerRef = ref;
    }

    getLocation() {
        this.innerRef && this.innerRef.getLocation();
    }

    render() {
        const address = this.props.selectedAddress;
        const stepNumber = this.props.stepNumber;
        const jobSiteSearchDialogOpen = this.state.jobSiteSearchDialogOpen;
        const stepDescription = (address) ? SELECTED_STEP_DESCRIPTION : STEP_DESCRIPTION;
        return (
            <ProductPurchaseStep completed={this.props.completed} stepNumber={stepNumber} stepDescription={stepDescription}>
                {(address === null) ?
                    (
                        <Button variant="contained" color="primary" onClick={this.showSearchDialog}>Search</Button>
                    ) : (
                        <Chip icon={<LocationOnIcon />} label={address.street_address} onClick={this.showSearchDialog} />
                    )}
                <ChooseLocationDialog ref={this.getInnerRef} open={jobSiteSearchDialogOpen} onCancel={this.didCancelLocation} onChooseLocation={this.didChooseLocation} />
            </ProductPurchaseStep>
        );
    }
}

export default JobSitePurchaseStep;