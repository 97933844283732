import React from "react";
import { Auth0Context } from "./react-auth0-wrapper";

class ProtectedComponent extends React.Component {

  static contextType = Auth0Context;

  render() {
    const Component = this.props.component;
    return (
      <div>
        {(!this.context.loading && this.context.isAuthenticated) ? (
          <Component {...this.props} />
        ) : null}
        <Auth0Context.Consumer>
          {() => {
            if (!this.context.loading && !this.context.isAuthenticated) {
              this.context.loginWithRedirect({
                appState: { targetUrl: this.props.location.pathname }
              });
            }
          }}
        </Auth0Context.Consumer>
      </div>
    );
  }
}

export default ProtectedComponent;