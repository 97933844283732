import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "./auth/react-auth0-wrapper";
import config from "./auth/auth_config.json";
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import Environment from './utils/Environment.js';

const onRedirectCallback = appState => {
    const targetLocation = appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname;
    const history = onRedirectCallback.history || createBrowserHistory();
    history.replace(targetLocation, {});
};

const onCreateHistory = history => {
    onRedirectCallback.history = history;
};

if (!Environment.isDevelopment) {
    ReactGA.initialize('UA-120960453-3');
}

ReactDOM.render(
    (<Auth0Provider
        audience={config.audience}
        domain={config.domain}
        client_id={config.clientId}
        scope={config.scope}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
    >
        <App onCreateHistory={onCreateHistory} />
    </Auth0Provider>),
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
