import React from 'react';
import Columns from 'react-bulma-components/lib/components/columns';
import GetFeedRequest from './api/GetFeedRequest.js';
import ProductFeatureFeedItem from './ProductFeatureFeedItem.js';
import ProductPurchaseFeedItem from './ProductPurchaseFeedItem.js';
import chunk from 'lodash/chunk';

class Feed extends React.Component {
    static get renderableFeedItemTypes() {
        return [GetFeedRequest.productPurchaseFeedItemType, GetFeedRequest.productFeatureFeedItemType];
    }

    render() {
        const title = this.props.title;
        const feedItems = this.props.feedItems.filter(f => Feed.renderableFeedItemTypes.includes(f.item_type));
        var result = chunk(feedItems, 2).map(f => {
            const secondElement = (f.length > 1) ? (<Columns.Column>{this.renderFeedItem(f[1])}</Columns.Column>) : null;
            return (
                <Columns key={f[0].identifier}>
                    <Columns.Column>
                    {this.renderFeedItem(f[0])}
                    </Columns.Column>
                    {(secondElement) ? secondElement : <Columns.Column />}
                </Columns>
            );
        });
        let titleElement = null;
        if (title && title !== "") {
            titleElement = (
                <Columns key="title" gapless>
                    <Columns.Column>
                        <p className="feedSectionTitle">{title}</p>
                    </Columns.Column>
                    <Columns.Column>
                        <p className="feedSectionTitle" />
                    </Columns.Column>
                </Columns>
            );
        }
        return (<div className="feed">{[titleElement, ...result]}</div>);
    }

    renderFeedItem(f) {
        if (f.item_type === GetFeedRequest.productPurchaseFeedItemType) {
            return (<ProductPurchaseFeedItem feedItem={f} />);
        } else if (f.item_type === GetFeedRequest.productFeatureFeedItemType) {
            return (<ProductFeatureFeedItem feedItem={f} />);
        } else {
            return null;
        }
    }
}

export default Feed;