import React from 'react';
import RequestExecutor from './api/RequestExecutor.js';
import ResponseCache from './api/ResponseCache.js';
import { Link } from 'react-router-dom';
import prefetched from './generated/prefetched_product_images.js';

class ProductPurchaseFeedItem extends React.Component {
    render() {
        const product = this.props.feedItem.product.product;
        // Update the cache now as a clickthrough of this item will require it.
        ResponseCache.setByProductIdentifier(product.identifier, product);
        if (product.slug && product.slug !== "") {
            ResponseCache.setByProductIdentifier(product.slug, product)
        }
        const prefetchedData = prefetched[product.identifier];
        const imageBannerURL = RequestExecutor.baseURL + '/' + product.banner_image_url;
        const imageBackground = prefetchedData ? `url(${imageBannerURL}), url(data:image/jpg;base64,${prefetchedData.banner})` : `url(${imageBannerURL})`;
        const imageStyle = {
            'background': `${imageBackground} no-repeat top left`,
            'backgroundColor': '#616161'
        };
        const fragment = this._fragment(product);
        return (
            <Link style={imageStyle} className="productPurchaseFeedItem" to={`/products/${fragment}`}>
                <div className="productName"><p className="productNameText">{product.display_name}</p></div>
            </Link>
        );
    }

    _fragment(product) {
        if (product.slug && product.slug !== "") {
            if (!RequestExecutor.storeContext && product.seller_short_name && product.seller_short_name !== "") {
                return `seller/${product.seller_short_name}/${product.slug}`;
            } else {
                return product.slug;
            }
        }
        return product.identifier;
    }
}

export default ProductPurchaseFeedItem;