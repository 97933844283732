import React from 'react';
import Button from '@material-ui/core/Button';
import Container from 'react-bulma-components/lib/components/container';
import Section from 'react-bulma-components/lib/components/section';

class ProductPurchaseCheckout extends React.Component {

    componentDidMount() {
        const button = document.getElementById("proceedToCheckoutButton");
        if (button) {
            button.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        return (
            <Section id="checkoutSection">
                <Container className="checkoutContainer">
                    <div id="checkoutContent">
                        <Button id="proceedToCheckoutButton" variant="contained" color="primary" onClick={this.props.onCheckout}>Checkout</Button>
                    </div>
                </Container>
            </Section>
        );
    }
}

export default ProductPurchaseCheckout;