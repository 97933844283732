import RequestExecutor from './RequestExecutor.js';

const MAPS_ENDPOINT = "/maps/tokens";

class GetMapTokenRequest {
    static execute() {
        return RequestExecutor.executeGet(MAPS_ENDPOINT, null, false);
    }
}

export default GetMapTokenRequest;