import RequestExecutor from './RequestExecutor.js';
import { UnitsSpecification } from '../model/Quantity.js';
import moment from 'moment';

const CREATE_PRODUCT_INVOICE_ENDPOINT = "/invoices";
const DEFAULT_COUNTRY_CODE = "US";

class CreateProductInvoiceRequest {
    static execute(product, address, quantity, deliveryTime) {
        const postData = {
            product_identifier: product.identifier,
            job_site: {
                location: `${address.location.latitude},${address.location.longitude}`,
                street_address: address.street_address,
                city: address.city,
                subregion: address.subregion,
                region: address.region,
                postal_code: address.postal_code,
                postal_address: address.full_address,
                iso_country_code: address.country_code || DEFAULT_COUNTRY_CODE
            },
            quantity: quantity.toRequestValue(),
            delivery_time: {
                requested_delivery_time_utc: (() => {
                    return moment.utc(deliveryTime.requestedDate.replace(" +0000", ""))
                            .add(deliveryTime.requestedTimeInterval.start.hour, 'h')
                            .add(deliveryTime.requestedTimeInterval.start.minute, 'm')
                            .format('YYYY-MM-DD HH:mm:ss +0000');
                })(),
                requested_delivery_time_interval_in_minutes: (() => {
                    const wallTimeToMinutesSinceMidnight = w => (w.hour * 60.0) + w.minute;
                    return wallTimeToMinutesSinceMidnight(deliveryTime.requestedTimeInterval.end) - wallTimeToMinutesSinceMidnight(deliveryTime.requestedTimeInterval.start);
                })()
            },
            estimate_only: false
        };
        return RequestExecutor.executePost(CREATE_PRODUCT_INVOICE_ENDPOINT, postData);
    }
}

export default CreateProductInvoiceRequest;