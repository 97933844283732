import React from 'react';
import ProductPurchaseStep from './ProductPurchaseStep.js';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import parseDate from './utils/parseDate.js';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import DeliveryTime from './model/DeliveryTime.js';

const SELECTED_STEP_DESCRIPTION = "Time";
const STEP_DESCRIPTION = "When do you need it?";

class DeliveryTimePurchaseStep extends React.Component {
    didUpdateSelectedDate;
    didUpdateSelectedTime;
    didChooseDeliveryTime;
    didSelectDeliveryTimeChip;

    constructor(props) {
        super(props);
        this.didUpdateSelectedDate = this.didUpdateSelectedDate.bind(this);
        this.didUpdateSelectedTime = this.didUpdateSelectedTime.bind(this);
        this.didChooseDeliveryTime = this.didChooseDeliveryTime.bind(this);
        this.didSelectDeliveryTimeChip = this.didSelectDeliveryTimeChip.bind(this);
        this.state = { selectedDate: null, selectedTime: null, selectableDates: [], selectableTimesByDate: {} };
    }

    componentDidMount() {
        this._initializeDeliveryTimes();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.deliveryOptions !== this.props.deliveryOptions) {
            this._initializeDeliveryTimes();
        }
    }

    _initializeDeliveryTimes() {
        const calendar = this.props.deliveryOptions.available_delivery_times;
        const selectableDates = Object.keys(calendar.intervals)
            .map(k => {
                const result = parseDate(k);
                // Normalize to midnight.
                result.hour(0).minute(0).second(0).second(0).millisecond(0);
                result._serviceTimeValue = k;
                return result;
            })
            .sort((first, second) => Math.sign(first.valueOf() - second.valueOf()));
        const selectableTimesByDate = selectableDates.map(d => {
            const serviceTimeValue = d._serviceTimeValue;
            const intervals = calendar.intervals[serviceTimeValue];
            const result = {};
            result[serviceTimeValue] = intervals;
            return result;
        }).reduce((result, next) => Object.assign(result, next), {});
        this.setState(
            {
                selectedDate: selectableDates[0],
                selectedTime: selectableTimesByDate[selectableDates[0]._serviceTimeValue][0],
                selectableDates: selectableDates,
                selectableTimesByDate: selectableTimesByDate
            }
        );
    }

    didChooseDeliveryTime() {
        this.props.onChooseDeliveryTime({ requestedDate: this.state.selectedDate._serviceTimeValue, requestedTimeInterval: this.state.selectedTime });
    }

    didUpdateSelectedDate(event) {
        const key = event.target.value;
        const selectedDate = this.state.selectableDates.find(d => d._serviceTimeValue === key);
        if (selectedDate !== this.state.selectedDate) {
            this.setState({ selectedDate: selectedDate, selectedTime: this.state.selectableTimesByDate[selectedDate._serviceTimeValue][0] });
        }
    }

    didUpdateSelectedTime(event) {
        const key = event.target.value;
        const selectedDate = this.state.selectedDate;
        const selectedTime = this.state.selectableTimesByDate[selectedDate._serviceTimeValue].find(t => {
            const displayValue = DeliveryTime.displayValueForTimeInterval(t);
            return key === `${selectedDate._serviceTimeValue}-${displayValue}`;
        });
        if (selectedTime !== this.state.selectedTime) {
            this.setState({ selectedTime: selectedTime });
        }
    }

    didSelectDeliveryTimeChip() {
        this.props.onEditDeliveryTime();
    }

    render() {
        const deliveryTime = this.props.deliveryTime;
        const stepNumber = this.props.stepNumber;
        const stepDescription = (deliveryTime) ? SELECTED_STEP_DESCRIPTION : STEP_DESCRIPTION;
        const selectedDate = this.state.selectedDate;
        const selectedTime = this.state.selectedTime;
        return (
            <ProductPurchaseStep completed={deliveryTime || this.props.completed} stepNumber={stepNumber} stepDescription={stepDescription}>
                {(this.props.completed) ? (<Chip icon={<ScheduleIcon />} onClick={this.didSelectDeliveryTimeChip} label={DeliveryTime.displayValueForDeliveryTime(deliveryTime)} />) :
                    (<div>
                        <div className="deliveryTimeInputContainer">
                            <form className="dateForm" autoComplete="off">
                                <FormControl>
                                    <InputLabel htmlFor="dateNativeHelper">Day</InputLabel>
                                    <NativeSelect
                                        value={selectedDate ? selectedDate._serviceTimeValue : ""}
                                        onChange={this.didUpdateSelectedDate}
                                        input={<Input name="date" id="dateNativeHelper" />}
                                    >
                                        {
                                            this.state.selectableDates.map(d => (
                                                <option key={d._serviceTimeValue} value={d._serviceTimeValue}>{DeliveryTime.displayValueForDate(d)}</option>
                                            ))
                                        }
                                    </NativeSelect>
                                </FormControl>
                            </form>
                            {(selectedDate) ?
                                (<form className="timeForm" autoComplete="off">
                                    <FormControl>
                                        <InputLabel htmlFor="timeNativeHelper">Time</InputLabel>
                                        <NativeSelect
                                            value={selectedTime ? `${selectedDate._serviceTimeValue}-${DeliveryTime.displayValueForTimeInterval(selectedTime)}` : ""}
                                            onChange={this.didUpdateSelectedTime}
                                            input={<Input name="time" id="timeNativeHelper" />}
                                        >
                                            {
                                                this.state.selectableTimesByDate[selectedDate._serviceTimeValue].map(t => {
                                                    const displayValue = DeliveryTime.displayValueForTimeInterval(t);
                                                    const key = `${selectedDate._serviceTimeValue}-${displayValue}`;
                                                    return (<option key={key} value={key}>{displayValue}</option>);
                                                })
                                            }
                                        </NativeSelect>
                                    </FormControl>
                                </form>) : null
                            }
                        </div>
                        {(selectedDate && selectedTime) ? <Button id="chooseDeliveryTimeButton" variant="contained" color="primary" onClick={this.didChooseDeliveryTime}>Done</Button> : null}
                    </div>)}
            </ProductPurchaseStep>
        );
    }
}

export default DeliveryTimePurchaseStep;