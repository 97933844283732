import React from 'react';
import Button from '@material-ui/core/Button';

class ErrorDisplay extends React.Component {
    render() {
        const errorAction = this.props.errorAction;
        return (
            <div>
                <p className="error-text has-text-centered">An error occurred. Please try again.</p>
                <Button variant="contained" color="primary" onClick={errorAction}>Retry</Button>
            </div>
        );
    }
}

export default ErrorDisplay;