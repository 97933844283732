import axios from 'axios';

const ROCKERY_API_BASE_URL = "/api/1.0";
const SUCCESS_RESULT_CODE = 0;
const OUT_OF_SERVICE_AREA_RESULT_CODE = 5;
const STORE_CONTEXT_REQUIRED = false;

class RequestExecutor {
    static get baseURL() {
        return ROCKERY_API_BASE_URL;
    }

    static executeGet(endpoint, query, unwrap = true) {
        const source = axios.CancelToken.source();
        const configuration = {
            cancelToken: source.token,
            params: query
        };
        const executor = () => {
            return axios.get(`${ROCKERY_API_BASE_URL}${endpoint}`, configuration);
        };
        const resultPromise = RequestExecutor._execute(executor, unwrap);
        resultPromise.cancellationToken = source;
        return resultPromise;
    }

    static executePost(endpoint, data, unwrap = true) {
        const source = axios.CancelToken.source();
        const executor = () => {
            return axios.post(`${ROCKERY_API_BASE_URL}${endpoint}`, data, { cancelToken: source.token });
        };
        const resultPromise = RequestExecutor._execute(executor, unwrap);
        resultPromise.cancellationToken = source;
        return resultPromise;
    }

    static cancel(promise, reason) {
        if (promise.cancellationToken) {
            promise.cancellationToken.cancel(reason);
            promise.cancellationToken = null;
        }
    }

    static setGuestAuthorizationToken(userIdentifier) {
        const authorizationHeader = axios.defaults.headers.common['Authorization'];
        if (authorizationHeader && authorizationHeader.includes("Bearer")) {
            // Do not override a bearer auth token without explicitly clearing it.
            return;
        }
        axios.defaults.headers.common['Authorization'] = `Guest-${userIdentifier}`;
    }

    static setBearerAuthorizationToken(token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    static setStoreContext(storeContext) {
        RequestExecutor.storeContext = storeContext;
    }

    static appendStoreContext(dataOrQuery) {
        if (dataOrQuery && RequestExecutor.storeContext && RequestExecutor.storeContext.identifier) {
            dataOrQuery["store_identifier"] = RequestExecutor.storeContext.identifier;
        } else if (STORE_CONTEXT_REQUIRED === true) {
            dataOrQuery["store_identifier"] = "invalid-store-identifier";
        }
        return dataOrQuery;
    }

    static clearAuthorization() {
        delete axios.defaults.headers.common['Authorization'];
    }

    static _execute(executor, unwrap) {
        return executor()
            .then(response => {
                if (unwrap) {
                    if (response.data.result_code !== SUCCESS_RESULT_CODE) {
                        const error = new Error("Service error");
                        error._serviceResponse = response;
                        throw error;
                    }
                    return response.data.data;
                } else {
                    return response.data;
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                }
                if (error.response && error.response.data && error.response.data.result_code) {
                    error._serviceResponse = error.response;
                }
                throw error;
            });
    }

    static _errorCodeForServiceError(error) {
        return error._serviceResponse.data.result_code;
    }

    static errorMessageForServiceError(error) {
        if (!error._serviceResponse) return null;
        return error._serviceResponse.data.messages[0];
    }

    static isOutOfServiceAreaError(error) {
        return error._serviceResponse && this._errorCodeForServiceError(error) === OUT_OF_SERVICE_AREA_RESULT_CODE;
    }
}

RequestExecutor.storeContext = null;

export default RequestExecutor;