import RequestExecutor from './RequestExecutor.js';

const SUGGESTIONS_ENDPOINT = "/orders/job_sites/search";

class GetAddressSuggestionsRequest {
    static execute(search, location, maxSuggestions) {
        const query = { query: search, location: `${location.latitude},${location.longitude}`, max_suggestions: maxSuggestions };
        return RequestExecutor.executeGet(SUGGESTIONS_ENDPOINT, query);
    }
}

export default GetAddressSuggestionsRequest;