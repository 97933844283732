import React from "react";
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Progress from './Progress.js';
import ErrorDisplay from './ErrorDisplay.js';
import GetOrderRequest from './api/GetOrderRequest.js';
import DoneIcon from '@material-ui/icons/Done';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TerrainIcon from '@material-ui/icons/Terrain';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonIcon from '@material-ui/icons/Person';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import Quantity from './model/Quantity.js';
import DeliveryTime from './model/DeliveryTime.js';
import formatCurrencyString from './utils/formatCurrencyString.js';

class Confirmation extends React.Component {
    fetchOrder;

    constructor(props) {
        super(props);
        const orderIdentifier = this.props.location.pathname.split('/')[2];
        this.state = {
            orderIdentifier: orderIdentifier,
            order: null,
            orderFetchError: null
        };
        this.fetchOrder = this.fetchOrder.bind(this);
    }

    componentDidMount() {
        this.fetchOrder();
        this.props.showFooter(false);
    }

    fetchOrder() {
        this.props.authorize()
            .then(() => GetOrderRequest.execute(this.state.orderIdentifier))
            .then(data => {
                this.setState({ order: data });
            })
            .catch(error => {
                console.log(error);
                this.setState({ orderFetchError: error });
            });
    }

    deliveryChargeForOrder(order) {
        return order.invoice.delivery_charge_in_dollars;
    }

    deliveryChargeDescriptionForOrder(order) {
        if (order.invoice.delivery_charge_description && order.invoice.delivery_charge_description !== "") {
            return order.invoice.delivery_charge_description;
        }
        return "Delivery";
    }

    salesTaxForOrder(order) {
        const addonInvoicesSalesTax = (order.additional_invoices || []).reduce((total, next) => total + next.total_sales_tax_in_dollars, 0);
        return addonInvoicesSalesTax + order.invoice.total_sales_tax_in_dollars;
    }

    subtotalForOrder(order) {
        const addonInvoicesSubtotal = (order.additional_invoices || []).reduce((total, next) => total + next.subtotal_in_dollars, 0);
        return addonInvoicesSubtotal + order.invoice.subtotal_in_dollars;
    }

    grandTotalForOrder(order) {
        const addonInvoicesTotal = (order.additional_invoices || []).reduce((total, next) => total + next.grand_total_in_dollars, 0);
        return addonInvoicesTotal + order.invoice.grand_total_in_dollars;
    }

    render() {
        const order = this.state.order;
        const orderFetchError = this.state.orderFetchError;
        if (order === null) {
            return (
                <div className="confirmationLoading has-text-centered">
                    {(orderFetchError) ? <ErrorDisplay errorAction={this.fetchOrder} /> : <Progress />}
                </div>
            );
        } else {
            return (
                <div>
                    <Section id="confirmationSection">
                        <Container className="confirmationFormContainer">
                            <div id="confirmationHeader" className="confirmationInvoiceContainer">
                                <div id="confirmationIcon" class="has-text-centered" >
                                    <DoneIcon id="confirmationDoneIcon" />
                                </div>
                                <div class="has-text-centered">
                                    <h1>Order Confirmed</h1>
                                </div>
                            </div>
                        </Container>
                    </Section>
                    <Section id="confirmationSection">
                        <Container className="confirmationFormContainer">
                            <div className="confirmationInvoiceContainer">
                                <h1 className="header">{order.product.display_name}</h1>
                                <div className="confirmationIconHeader">
                                    <LocationOnIcon />
                                    <h2 className="header">Job Site</h2>
                                </div>
                                <div className="confirmationContent">
                                    <div className="confirmationContentSpacer" />
                                    <p>{order.job_site.street_address}<br />{order.job_site.city}, {order.job_site.region} {order.job_site.postal_code}</p>
                                </div>
                                <div className="confirmationIconHeader">
                                    <TerrainIcon />
                                    <h2 className="header">Amount</h2>
                                </div>
                                <div className="confirmationContent">
                                    <div className="confirmationContentSpacer" />
                                    <p>{Quantity.fromRequestValue(order.invoice.quantity).toString()}</p>
                                </div>
                                <div className="confirmationIconHeader">
                                    <ScheduleIcon />
                                    <h2 className="header">Time</h2>
                                </div>
                                <div className="confirmationContent">
                                    <div className="confirmationContentSpacer" />
                                    <p>{DeliveryTime.displayValueFromDelivery(order.delivery)}</p>
                                </div>
                                <div className="confirmationIconHeader">
                                    <PersonIcon />
                                    <h2 className="header">Site Contact</h2>
                                </div>
                                <div className="confirmationContent">
                                    <div className="confirmationContentSpacer" />
                                    <p>{order.job_site.contact_name}<br />{order.job_site.contact_phone_number}</p>
                                </div>
                                <div className="confirmationIconHeader">
                                    <MoneyIcon />
                                    <h2 className="header">Purchase</h2>
                                </div>
                                <div className="confirmationContent">
                                    <div className="confirmationContentSpacer" />
                                    <p>{formatCurrencyString(this.subtotalForOrder(order))}{this.deliveryChargeForOrder(order) ? ` + ${formatCurrencyString(this.deliveryChargeForOrder(order))} ${this.deliveryChargeDescriptionForOrder(order)}` : ""} + {formatCurrencyString(this.salesTaxForOrder(order))} tax<br />Total {formatCurrencyString(this.grandTotalForOrder(order))}</p>
                                </div>
                            </div>
                        </Container>
                    </Section>
                </div>
            );
        }
    }
}

export default Confirmation;