import RequestExecutor from './RequestExecutor.js';

const SAVE_ORDER_ENDPOINT = "/orders/saved";

class SaveOrderRequest {
    static execute(invoice, siteContact, metadata) {
        const data = {
            invoice_id: invoice.identifier,
            site_contact_name: siteContact.name,
            site_contact_phone_number: siteContact.phoneNumber,
            metadata: metadata
        };
        return RequestExecutor.executePost(SAVE_ORDER_ENDPOINT, data);
    }
}

export default SaveOrderRequest;