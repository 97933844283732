import RequestExecutor from './RequestExecutor.js';

const REGISTER_USER_ENDPOINT = "/users/register";

class RegisterUserRequest {
    static execute(accessToken) {
        const data = { access_token: accessToken };
        return RequestExecutor.executePost(REGISTER_USER_ENDPOINT, data);
    }
}

export default RegisterUserRequest;