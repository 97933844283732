import React from 'react';
import Container from 'react-bulma-components/lib/components/container';
import RequestExecutor from './api/RequestExecutor.js';
import Progress from './Progress.js';

class ProductFeatureImage extends React.Component {
    render() {
        const product = this.props.product;
        const fullSizePicture = product.full_size_pictures.filter(p => p.label.toLowerCase() === 'dry');
        const framed = (fullSizePicture[0]) ? fullSizePicture[0].presentation_style === "framed" : false;
        const framedBackgroundColor = (fullSizePicture[0]) ? fullSizePicture[0].framed_background_color : null;
        const fullSizeURLFragment = (fullSizePicture[0]) ? fullSizePicture[0].url : null;
        const productImageURL = (fullSizeURLFragment) ? RequestExecutor.baseURL + '/' + fullSizeURLFragment : null;
        const imgElement = (productImageURL) ? (<img src={productImageURL} alt={product.display_name} className="fullSizeProductImage" />) : null;
        const imgWrapper = (framed) ? (<div className="has-text-centered">{imgElement}</div>) : imgElement;
        const imageContainerClassName = `productPurchaseImageContainer productFeatureContainerImageHeight`;
        const imageContainerStyle = (framed && framedBackgroundColor) ? {backgroundColor: framedBackgroundColor} : {};
        return (
            <Container style={imageContainerStyle} className={imageContainerClassName}>
                <Container id="productImageLoading">
                    <div className="has-text-centered">
                        <Progress />
                    </div>
                </Container>
                {imgWrapper}
            </Container>
        );
    }
}

export default ProductFeatureImage;