import React from 'react';
import Container from 'react-bulma-components/lib/components/container';
import Section from 'react-bulma-components/lib/components/section';
import ResponseCache from './api/ResponseCache.js';
import FullSizeProductImage from './FullSizeProductImage.js';
import Progress from './Progress.js';
import GetProductRequest from './api/GetProductRequest.js';
import GetStoreProductRequest from './api/GetStoreProductRequest.js';
import ErrorDisplay from './ErrorDisplay.js';
import PurchaseModel from './model/PurchaseModel.js';
import JobSitePurchaseStep from './JobSitePurchaseStep.js';
import QuantityPurchaseStep from './QuantityPurchaseStep.js';
import DeliveryTimePurchaseStep from './DeliveryTimePurchaseStep.js';
import RequestExecutor from './api/RequestExecutor.js';
import GetProductDeliveryOptionsRequest from './api/GetProductDeliveryOptionsRequest.js';
import SiteContactPurchaseStep from './SiteContactPurchaseStep.js';
import CreateProductInvoiceRequest from './api/CreateProductInvoiceRequest.js';
import GetProductAddonsRequest from './api/GetProductAddonsRequest.js';
import formatCurrencyString from './utils/formatCurrencyString.js';
import ProductPurchaseCheckout from './ProductPurchaseCheckout.js';
import ErrorAlertDialog from './ErrorAlertDialog.js';
import ProgressDialog from './ProgressDialog.js';
import SaveOrderRequest from './api/SaveOrderRequest.js';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import InfoIcon from '@material-ui/icons/Info';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import SynthGrassCutsComponent from './SynthGrassCutsComponent.js';
import { Quantity } from './model/Quantity.js';

const SELLER_SLUG = "/products/seller/";

class ProductPurchase extends React.Component {
    closeErrorDialog;
    closePopover;
    closeProgressDialog;
    fetchProduct;
    openPopover;
    onChooseAddress;
    onChooseQuantity;
    onChooseDeliveryTime;
    onChooseSiteContact;
    onEditQuantity;
    onEditDeliveryTime;
    onEditSiteContact;
    onCheckout;

    constructor(props) {
        super(props);
        const parsedLocation = this._parseLocation();
        const queryComponents = queryString.parse(this.props.location.search);
        const productIdentifier = parsedLocation.productIdentifier;
        const sellerShortName = parsedLocation.sellerShortName;
        const product = ResponseCache.getByProductIdentifier(productIdentifier);
        const purchaseModel = (product !== null) ? new PurchaseModel(product) : null;
        this.state = {
            productAddons: null,
            productIdentifier: productIdentifier,
            purchaseModel: purchaseModel,
            productFetchError: null,
            isEditingQuantity: false,
            isEditingDeliveryTime: false,
            isEditingSiteContact: false,
            errorTitle: "",
            errorMessage: "",
            showingError: false,
            showingProgress: false,
            progressTitle: "",
            showProductMetadata: queryComponents.withMetadata === "true",
            popoverAnchorElement: null,
            sellerShortName: sellerShortName
        }
        this.closeErrorDialog = this.closeErrorDialog.bind(this);
        this.closeProgressDialog = this.closeProgressDialog.bind(this);
        this.fetchProduct = this.fetchProduct.bind(this);
        this.onChooseAddress = this.onChooseAddress.bind(this);
        this.onChooseQuantity = this.onChooseQuantity.bind(this);
        this.onChooseDeliveryTime = this.onChooseDeliveryTime.bind(this);
        this.onEditQuantity = this.onEditQuantity.bind(this);
        this.onChooseSiteContact = this.onChooseSiteContact.bind(this);
        this.onEditDeliveryTime = this.onEditDeliveryTime.bind(this);
        this.onEditSiteContact = this.onEditSiteContact.bind(this);
        this.onCheckout = this.onCheckout.bind(this);
        this.openPopover = this.openPopover.bind(this);
        this.closePopover = this.closePopover.bind(this);
    }

    componentDidMount() {
        this.fetchProduct();
        this.props.showFooter(false);
    }

    componentDidUpdate(prevProps, prevState) {
        const previousPurchaseModel = prevState.purchaseModel;
        const purchaseModel = this.state.purchaseModel;
        const previousAddress = (previousPurchaseModel) ? previousPurchaseModel.address : null;
        const address = (purchaseModel) ? purchaseModel.address : null;
        if (purchaseModel && address && previousAddress !== address) {
            this._fetchDeliveryOptions();
        } else if (purchaseModel && previousPurchaseModel) {
            const previousQuantity = previousPurchaseModel.quantity;
            const quantity = purchaseModel.quantity;
            const previousDeliveryTime = previousPurchaseModel.deliveryTime;
            const deliveryTime = purchaseModel.deliveryTime;
            if ((purchaseModel.invoice === null && (address && quantity && deliveryTime)) ||
                (purchaseModel.invoice && (address !== previousAddress || quantity !== previousQuantity || deliveryTime !== previousDeliveryTime))) {
                this._fetchInvoice();
            }
        }
    }

    _parseLocation() {
        const location = this.props.location.pathname;
        if (location.startsWith(SELLER_SLUG)) {
            const components = this.props.location.pathname.split('/');
            return { sellerShortName: components[3], productIdentifier: components[4] };
        } else {
            return { productIdentifier: this.props.location.pathname.split('/')[2] };
        }
    }

    _fetchDeliveryOptions() {
        if (this.deliveryOptionsRequest) {
            RequestExecutor.cancel(this.deliveryOptionsRequest);
            this.deliveryOptionsRequest = null;
        }
        const purchaseModel = this.state.purchaseModel;
        this.props.authorize()
            .then(data => {
                this.deliveryOptionsRequest = GetProductDeliveryOptionsRequest.execute(purchaseModel.product.identifier, purchaseModel.address);
                return this.deliveryOptionsRequest;
            })
            .then(data => {
                this.deliveryOptionsRequest = null;
                this.setState((state, props) => ({
                    purchaseModel: state.purchaseModel.withDeliveryOptions(data)
                }));
            })
            .then(() => GetProductAddonsRequest.execute(this.state.purchaseModel.product.identifier))
            .then((productAddons) => this.setState({ productAddons: productAddons }))
            .catch(error => {
                this.deliveryOptionsRequest = null;
                if (RequestExecutor.isOutOfServiceAreaError(error)) {
                    this.setState((state, props) => ({
                        purchaseModel: state.purchaseModel.reset(),
                        showingError: true,
                        errorTitle: "Sorry",
                        errorMessage: "We do not currently serve that address. Please contact us for further assistance."
                    }));
                } else {
                    // TODO: retry.
                    console.log(error);
                }
            });
    }

    _fetchInvoice() {
        if (this.createProductInvoiceRequest) {
            RequestExecutor.cancel(this.createProductInvoiceRequest);
            this.createProductInvoiceRequest = null;
        }
        const purchaseModel = this.state.purchaseModel;
        this.createProductInvoiceRequest = CreateProductInvoiceRequest.execute(purchaseModel.product, purchaseModel.address, purchaseModel.quantity, purchaseModel.deliveryTime);
        this.createProductInvoiceRequest
            .then(data => {
                this.createProductInvoiceRequest = null;
                this.setState((state, props) => ({
                    purchaseModel: state.purchaseModel.withInvoice(data.invoice)
                }));
            })
            .catch(error => {
                // TODO: retry.
                this.createProductInvoiceRequest = null;
                console.log(error);
            });
    }

    _saveOrder() {
        const invoice = this.state.purchaseModel.invoice;
        const siteContact = this.state.purchaseModel.siteContact;
        const metadata = this.state.purchaseModel.metadata;
        if (!invoice || !siteContact || !siteContact.name || siteContact.name === "" || !siteContact.phoneNumber || siteContact.phoneNumber === "") {
            throw new Error("Invalid operation.");
        }
        this.setState({ showingProgress: true, progressTitle: "One Moment…" });
        SaveOrderRequest.execute(invoice, siteContact, metadata)
            .then(() => {
                this.closeProgressDialog();
                if (this.state.productAddons && this.state.productAddons.length > 1) {
                    this.props.history.push(`/addons/`);
                } else {
                    this.props.history.push(`/checkout/`);
                }
            })
            .catch(() => {
                this.closeProgressDialog();
                this.setState({ showingError: true, errorTitle: "Something went wrong.", errorMessage: "There was an error starting checkout. Please try again." });
            });
    }

    closeErrorDialog() {
        this.setState({
            showingError: false,
            errorTitle: "",
            errorMessage: ""
        });
    }

    closeProgressDialog() {
        this.setState({
            showingProgress: false,
            progressTitle: ""
        });
    }

    fetchProduct() {
        const product = this.state.product;
        const productIdentifier = this.state.productIdentifier;
        const sellerShortName = this.state.sellerShortName;
        if (!product) {
            if (productIdentifier) {
                this.setState({ productFetchError: null });
                this.props.fetchStoreContext()
                    .then(data => {
                        RequestExecutor.setStoreContext(data);
                    })
                    .then(() => {
                        if (sellerShortName) {
                            return GetStoreProductRequest.execute(sellerShortName, productIdentifier);
                        } else {
                            return GetProductRequest.execute(productIdentifier);
                        }
                    })
                    .then(data => {
                        this.setState({ purchaseModel: new PurchaseModel(data), productIdentifier: data.identifier });
                    })
                    .catch(error => {
                        this.setState({ productFetchError: error });
                    })
            } else {
                this.setState({ productFetchError: new Error("Invalid product identifier.") });
            }
        }
    }

    onChooseAddress(address) {
        this.setState((state, props) => ({
            purchaseModel: state.purchaseModel.withAddress(address)
        }));
    }

    onChooseQuantity(quantity) {
        this.setState((state, props) => ({
            isEditingQuantity: false,
            purchaseModel: state.purchaseModel.withQuantity(quantity)
        }));
    }

    onChooseDeliveryTime(deliveryTime) {
        this.setState((state, props) => ({
            isEditingDeliveryTime: false,
            purchaseModel: state.purchaseModel.withDeliveryTime(deliveryTime)
        }));
    }

    onChooseSiteContact(siteContact) {
        this.setState((state, props) => ({
            isEditingSiteContact: false,
            purchaseModel: state.purchaseModel.withSiteContact(siteContact)
        }));
    }

    onEditQuantity() {
        if (this.shouldShowQuantitySelector(this.state.purchaseModel.product)) {
            this.setState({ isEditingQuantity: true });
        }
    }

    onEditDeliveryTime() {
        this.setState({ isEditingDeliveryTime: true });
    }

    onEditSiteContact() {
        this.setState({ isEditingSiteContact: true })
    }

    onCheckout() {
        this._saveOrder();
    }

    openPopover(event) {
        this.setState({ popoverAnchorElement: event.target });
    }

    closePopover() {
        this.setState({ popoverAnchorElement: null });
    }

    updateOrderMetadata(value) {
        const metadata = this.state.purchaseModel.metadata || {};
        this.setState({ purchaseModel: this.state.purchaseModel.withMetadata(Object.assign(metadata, value)) });
    }

    shouldShowQuantitySelector(product) {
        return !(product.parent_sku === "synthGrass");
    }

    getCustomQuantityComponentForProduct(product) {
        const deliveryOptions = this.state.purchaseModel.deliveryOptions;
        if (deliveryOptions && product.parent_sku === "synthGrass") {
            const metadata = this.state.purchaseModel.metadata || {};
            const cutsMetadata = metadata["synthGrassCuts"];
            let cuts = [];
            if (cutsMetadata) {
                cuts = cutsMetadata.cuts;
            }
            const minimumQuantity = deliveryOptions.deliverable_quantities.map(q => {
                return Quantity.fromRequestValue(q.minimum_deliverable_quantity);
            })[0];
            const cutsUnits = minimumQuantity.units;
            const totalCuts = cuts.reduce((result, next) => result + next.size, 0);
            const quantity = Quantity.inUnits(cutsUnits, totalCuts);
            const computeQuantityFromCuts = (cuts) => Quantity.inUnits(cutsUnits, cuts.reduce((result, next) => result + next.size, 0));
            const didSelectSynthGrassCuts = (cuts) => {
                this.updateOrderMetadata({ synthGrassCuts: { cuts: cuts } });
                this.onChooseQuantity(computeQuantityFromCuts(cuts));
            };
            const deleteCut = (index) => {
                const metadata = this.state.purchaseModel.metadata || {};
                if (metadata && metadata.synthGrassCuts && metadata.synthGrassCuts.cuts) {
                    const cuts = metadata.synthGrassCuts.cuts;
                    cuts.splice(index, 1);
                    this.updateOrderMetadata({ synthGrassCuts: { cuts: cuts } });
                    this.onChooseQuantity(computeQuantityFromCuts(cuts));
                }
            };
            return <SynthGrassCutsComponent cuts={cuts} selectedQuantity={quantity} minimumQuantity={minimumQuantity} cutsUnits={cutsUnits} totalCuts={totalCuts} deleteCut={deleteCut} didSelectSynthGrassCuts={didSelectSynthGrassCuts} />
        }
        return null;
    }

    render() {
        const productFetchError = this.state.productFetchError;
        const purchaseModel = this.state.purchaseModel;
        if (purchaseModel === null) {
            return (
                <div className="productLoading has-text-centered">
                    {(productFetchError) ? <ErrorDisplay errorAction={this.fetchProduct} /> : <Progress />}
                </div>
            );
        }
        const popoverAnchorElement = this.state.popoverAnchorElement;
        const popoverOpen = Boolean(popoverAnchorElement);
        const popoverId = popoverOpen ? 'contactPopover' : null;
        const shouldShowQuantitySelector = this.shouldShowQuantitySelector(this.state.purchaseModel.product);
        const customQuantityElement = this.getCustomQuantityComponentForProduct(this.state.purchaseModel.product);
        const deliveryCharge = (purchaseModel && purchaseModel.invoice) ? purchaseModel.invoice.delivery_charge_in_dollars : null;
        const hasDeliveryCharge = deliveryCharge !== null && typeof deliveryCharge !== 'undefined' && deliveryCharge >= 0;
        const deliveryDescription = purchaseModel != null ? purchaseModel.getDeliveryDescription() : "";
        return (
            <div>
                <Helmet>
                    <title>Rockery | {this.state.purchaseModel.product.display_name} delivery to your job site.</title>
                    <meta content={`${this.state.purchaseModel.product.display_name} delivered to your job site with scheduling, partial loads, and free same-day service.`} name="description" />
                    <meta content={`${this.state.purchaseModel.product.display_name} delivery to your job site.`} property="og:title" />
                    <meta content={`${this.state.purchaseModel.product.display_name} delivered to your job site with scheduling, partial loads, and free same-day service.`} property="og:description" />
                </Helmet>
                <ProgressDialog open={this.state.showingProgress} title={this.state.progressTitle} />
                <ErrorAlertDialog open={this.state.showingError} title={this.state.errorTitle} message={this.state.errorMessage} onClose={this.closeErrorDialog} />
                <Section id="productImageSection">
                    <FullSizeProductImage product={purchaseModel.product} collapsed={purchaseModel.address !== null} />
                </Section>
                <Section id="productDescriptionSection">
                    <Container id="productDescriptionContainer">
                        <p className="productDescriptionText productDescriptionTextSize">{purchaseModel.product.display_name}</p>
                    </Container>
                </Section>
                {(purchaseModel.invoice) ? (
                    <Section id="productPriceSection">
                        <Container id="productPriceContainer">
                <p className="productPriceText productDescriptionTextSize">{formatCurrencyString(purchaseModel.invoice.subtotal_in_dollars)} {(!hasDeliveryCharge) ? (<span id="freeShippingText">FREE delivery</span>) : null}</p>
                        </Container>
                    </Section>
                ) : null}
                {
                    (this.state.showProductMetadata) ? (
                        <Section id="productMetadataSection">
                            <Container id="productMetadataContainer">
                                <h2 className="productMetadataHeader">About the Product</h2>
                                <p className="productMetadataDescription">{this.state.purchaseModel.product.description}</p>
                                <div className="productMetadataChipsContainer">
                                    {this.state.purchaseModel.product.descriptive_attributes.map(c => <div className="metadataChip" key={c}>{c}</div>)}
                                </div>
                                <h2 className="productMetadataHeader serviceDescription">Our Service</h2>
                                <p className="productMetadataDescription">Rockery delivers this {this.state.purchaseModel.product.display_name} free with your purchase. Satisfaction guaranteed.</p>
                                <div className="productMetadataChipsContainer">
                                    <Chip icon={<InfoIcon />} component="a" href="https://www.rockeryapp.com" target="_blank" rel="noopener noreferrer" label="Learn More" clickable />
                                    <Chip icon={<ContactSupportIcon />} onClick={this.openPopover} label="Contact Us" clickable />
                                    <Popover
                                        id={popoverId}
                                        open={popoverOpen}
                                        anchorEl={popoverAnchorElement}
                                        onClose={this.closePopover}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div className="contactPopoverContent">
                                            <div className="contactPopoverElement"><a href="tel://+19255772454"><FontAwesomeIcon icon={faPhone} /> (925) 577-2454</a></div><br />
                                            <div className="contactPopoverElement"><a href="mailto:getrock@rockeryapp.com"><FontAwesomeIcon icon={faEnvelopeSquare} /> support@rockeryapp.com</a></div>
                                        </div>
                                    </Popover>
                                </div>
                            </Container>
                        </Section>
                    ) : null
                }
                <Section id="productPurchaseSection">
                    {(deliveryDescription && deliveryDescription !== "") ? <Container className="productPurchaseStepContainer"><p className="productDeliveryDescription">{deliveryDescription}</p></Container> : null}
                    <JobSitePurchaseStep completed={purchaseModel.address != null} stepNumber="1" selectedAddress={purchaseModel.address} onChooseAddress={this.onChooseAddress} />
                    {(purchaseModel.address) ? <QuantityPurchaseStep shouldShowQuantitySelector={shouldShowQuantitySelector} customQuantityElement={customQuantityElement} onChooseQuantity={this.onChooseQuantity} onEditQuantity={this.onEditQuantity} completed={!this.state.isEditingQuantity && purchaseModel.quantity !== null} stepNumber="2" quantity={purchaseModel.quantity} deliveryOptions={purchaseModel.deliveryOptions} /> : null}
                    {(purchaseModel.deliveryOptions && purchaseModel.quantity) ? <DeliveryTimePurchaseStep stepNumber="3" completed={!this.state.isEditingDeliveryTime && purchaseModel.deliveryTime !== null} onChooseDeliveryTime={this.onChooseDeliveryTime} onEditDeliveryTime={this.onEditDeliveryTime} deliveryTime={purchaseModel.deliveryTime} deliveryOptions={purchaseModel.deliveryOptions} /> : null}
                    {(purchaseModel.deliveryOptions && purchaseModel.quantity && purchaseModel.deliveryTime) ? <SiteContactPurchaseStep stepNumber="4" completed={!this.state.isEditingSiteContact && purchaseModel.siteContact !== null} siteContact={purchaseModel.siteContact} onChooseSiteContact={this.onChooseSiteContact} onEditSiteContact={this.onEditSiteContact} /> : null}
                </Section>
                {(purchaseModel.deliveryOptions && purchaseModel.quantity && purchaseModel.deliveryTime && purchaseModel.siteContact && purchaseModel.invoice) ? <ProductPurchaseCheckout onCheckout={this.onCheckout} /> : null}
            </div>
        );
    }
}

export default ProductPurchase;