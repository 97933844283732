import Quantity from './Quantity.js';

class PurchaseModel {
    product;
    address;
    quantity;
    deliveryTime;
    siteContact;
    deliveryOptions;
    invoice;
    metadata;

    constructor(product,
        address = null,
        quantity = null,
        deliveryTime = null,
        siteContact = null,
        deliveryOptions = null,
        invoice = null,
        metadata = null) {
        if (product === null) {
            throw new Error("A product is required.");
        }
        // TODO: valdiate the shape of the input data.
        this.product = product;
        this.address = address;
        this.quantity = quantity;
        this.deliveryTime = deliveryTime;
        this.siteContact = siteContact;
        this.deliveryOptions = deliveryOptions;
        this.invoice = invoice;
        this.metadata = metadata;
        this.validateQuantity();
        this.validateDeliveryTime();
    }

    validateQuantity() {
        if (this.deliveryOptions && this.quantity) {
            // TODO: implement.
        }
    }

    validateDeliveryTime() {
        if (this.deliveryOptions && this.deliveryTime) {
            // TODO: implement.
        }
    }

    withAddress(address) {
        return new PurchaseModel(this.product, address, this.quantity, this.deliveryTime, this.siteContact, this.deliveryOptions, this.invoice, this.metadata);
    }

    withQuantity(quantity) {
        return new PurchaseModel(this.product, this.address, quantity, this.deliveryTime, this.siteContact, this.deliveryOptions, this.invoice, this.metadata);
    }

    withDeliveryTime(deliveryTime) {
        return new PurchaseModel(this.product, this.address, this.quantity, deliveryTime, this.siteContact, this.deliveryOptions, this.invoice, this.metadata);
    }

    withSiteContact(siteContact) {
        return new PurchaseModel(this.product, this.address, this.quantity, this.deliveryTime, siteContact, this.deliveryOptions, this.invoice, this.metadata);
    }

    withDeliveryOptions(deliveryOptions) {
        return new PurchaseModel(this.product, this.address, this.quantity, this.deliveryTime, this.siteContact, deliveryOptions, this.invoice, this.metadata);
    }

    withInvoice(invoice) {
        return new PurchaseModel(this.product, this.address, this.quantity, this.deliveryTime, this.siteContact, this.deliveryOptions, invoice, this.metadata);
    }

    withMetadata(metadata) {
        return new PurchaseModel(this.product, this.address, this.quantity, this.deliveryTime, this.siteContact, this.deliveryOptions, this.invoice, metadata);
    }

    getDeliveryDescription() {
        const deliveryPricing = this.product.delivery_pricing;
        if (!deliveryPricing || !deliveryPricing.delivery_charge_in_dollars || deliveryPricing.delivery_charge_in_dollars === 0) return "";
        const quantityThreshold = deliveryPricing.free_delivery_quantity_threshold;
        const promoText = deliveryPricing.promotional_message;
        if (!promoText || promoText === "") {
            if (quantityThreshold) {
                return `Free Delivery for orders over ${Quantity.fromRequestValue(quantityThreshold).toString()}`;
            }
        } else {
            return promoText;
        }
        return "";
    }

    reset() {
        return new PurchaseModel(this.product);
    }
}

export default PurchaseModel;