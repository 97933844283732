import RequestExecutor from './RequestExecutor.js';

const GET_SAVED_ORDER_ENDPOINT = "/orders/saved";

class GetSavedOrderRequest {
    static execute() {
        return RequestExecutor.executeGet(GET_SAVED_ORDER_ENDPOINT);
    }
}

export default GetSavedOrderRequest;