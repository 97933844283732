import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class ProductFeatureDetailsDialog extends React.Component {
    render() {
        const open = this.props.open;
        const onCloseProductDetailsDialog = this.props.onCloseProductDetailsDialog;
        const product = this.props.product;
        return (
            <Dialog
                open={open}
                aria-labelledby="progress-dialog-title"
            >
                <DialogTitle id="progress-dialog-title">{product.display_name}</DialogTitle>
                <DialogContent>
                    <div>
                        {product.description.split("\n").map((i, key) => {
                            if (i === "") {
                                return <div key={key}><br/></div>;
                            } 
                            return <div key={key}>{i}</div>;
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseProductDetailsDialog} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ProductFeatureDetailsDialog;