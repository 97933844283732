import React from 'react';
import Container from 'react-bulma-components/lib/components/container';
import Section from 'react-bulma-components/lib/components/section';
import ErrorDisplay from './ErrorDisplay.js';
import Feed from './Feed.js';
import Progress from './Progress.js';
import GetFeedRequest from './api/GetFeedRequest.js';
import ResponseCache from './api/ResponseCache.js';

class HomeFeed extends React.Component {
    authorize;
    fetchHomeFeed;

    constructor(props) {
        super(props);
        this.authorize = props.authorize;
        this.fetchHomeFeed = props.fetchHomeFeed;
        this.state = { userIdentifier: ResponseCache.getGuestUserIdentifier(), lastError: null, feedItems: ResponseCache.getHomeFeedItemsResponse() };
    }

    componentDidMount() {
        this.props.showFooter(false);
        const userIdentifier = this.state.userIdentifier;
        const feedItems = this.state.feedItems;
        if (userIdentifier === null || feedItems === null ) {
            this._fetchHomeFeed();
        }
    }

    _fetchHomeFeed() {
        this.setState({ userIdentifier: null, lastError: null, feedItems: null });
        this.authorize()
            .then(userIdentifier => {
                this.setState({ userIdentifier: userIdentifier });
                return this.fetchHomeFeed();
            })
            .then(feedItems => {
                this.props.showFooter(true);
                ResponseCache.setHomeFeedItemsResponse(feedItems);
                this.setState({ feedItems: feedItems });
            })
            .catch(error => {
                this.setState({ lastError: error, feedItems: null });
            });
    }

    render() {
        const userIdentifier = this.state.userIdentifier;
        const feedItems = this.state.feedItems;
        const lastError = this.state.lastError;
        let containedElement;
        if (lastError !== null) {
            containedElement = <ErrorDisplay errorAction={this.fetchHomeFeed} />;
        } else if (userIdentifier === null || feedItems === null) {
            containedElement = <Progress />;
        } else {
            // TODO: for now, only render embedded sections.
            containedElement = feedItems.items
                .filter(i => i.item_type === GetFeedRequest.groupFeedItemType)
                .filter(i => i.items.some(fi => Feed.renderableFeedItemTypes.includes(fi.item_type)))
                .map(i => (
                    <div key={i.identifier}>
                        <Feed feedItems={i.items} title={i.title}/>
                    </div>
                ));
        }
        return (
            <Section id="feedSection">
                <Container fluid id="feedContainer">
                    <div className="has-text-centered">
                        {containedElement}
                    </div>
                </Container>
            </Section>
        );
    }
}

export default HomeFeed;