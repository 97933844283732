import RequestExecutor from './RequestExecutor.js';

const GET_PRODUCT_ENDPOINT = "/products/";

class GetProductRequest {
    static execute(productIdentifierOrSlug) {
        const query = { includes_delivery_pricing: true };
        return RequestExecutor.executeGet(`${GET_PRODUCT_ENDPOINT}${productIdentifierOrSlug}`, RequestExecutor.appendStoreContext(query));
    }
}

export default GetProductRequest;