import RequestExecutor from './RequestExecutor.js';

const FEED_ENDPOINT = '/feeds/generate'

const FEED_ITEM_TYPE_BASE = "com.buildingpicks.feed.items";
const GROUP_FEED_ITEM_TYPE = ".group";
const PRODUCT_FEATURE_FEED_ITEM_TYPE = ".product_feature";
const PRODUCT_PURCHASE_FEED_ITEM_TYPE = ".product_purchase";

const CLIENT_IDENTIFIER = "rockery-web";
const CLIENT_VERSION = "0.1.0";
const BUILD = "1";

class GetFeedRequest {
    static get groupFeedItemType() {
        return `${FEED_ITEM_TYPE_BASE}${GROUP_FEED_ITEM_TYPE}`;
    }

    static get productFeatureFeedItemType() {
        return `${FEED_ITEM_TYPE_BASE}${PRODUCT_FEATURE_FEED_ITEM_TYPE}`;
    }

    static get productPurchaseFeedItemType() {
        return `${FEED_ITEM_TYPE_BASE}${PRODUCT_PURCHASE_FEED_ITEM_TYPE}`;
    }

    static execute(feedName) {
        const request = {
            feed_name: feedName,
            options: {
                maximum_group_nesting_level: 2,
                client_identifier: CLIENT_IDENTIFIER,
                client_version: {
                    build: BUILD,
                    version: CLIENT_VERSION,
                },
                supported_feed_item_types: [GetFeedRequest.groupFeedItemType, GetFeedRequest.productFeatureFeedItemType, GetFeedRequest.productPurchaseFeedItemType]
            }
        };
        return RequestExecutor.executePost(FEED_ENDPOINT, RequestExecutor.appendStoreContext(request));
    }
}

export default GetFeedRequest;