import React from 'react';
import ProductPurchaseStep from './ProductPurchaseStep.js';
import Progress from './Progress.js';
import TerrainIcon from '@material-ui/icons/Terrain';
import Chip from '@material-ui/core/Chip';
import { Quantity, QuantityValue } from './model/Quantity.js';
import QuantitySelector from './QuantitySelector';

const SELECTED_STEP_DESCRIPTION = "Amount";
const STEP_DESCRIPTION = "How much?";

class QuantityPurchaseStep extends React.Component {
    didChooseQuantity;
    didUpdateSelectedQuantity;
    didUpdateSelectedUnits;
    didSelectQuantityChip;

    constructor(props) {
        super(props);
        this.didChooseQuantity = this.didChooseQuantity.bind(this);
        this.didUpdateSelectedQuantity = this.didUpdateSelectedQuantity.bind(this);
        this.didUpdateSelectedUnits = this.didUpdateSelectedUnits.bind(this);
        this.didSelectQuantityChip = this.didSelectQuantityChip.bind(this);
        this.state = { selectedQuantity: null, selectedUnits: null, selectableUnits: [], selectableQuantitiesByUnits: {} };
    }

    componentDidMount() {
        this._initSelectionValues();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.deliveryOptions !== this.props.deliveryOptions) {
            this._initSelectionValues();
        }
    }

    _initSelectionValues() {
        const deliveryOptions = this.props.deliveryOptions;
        const selectedQuantity = this.state.selectedQuantity;
        const selectedUnits = this.state.selectedUnits;
        if (deliveryOptions) {
            if (!selectedUnits || !selectedQuantity) {
                const selectableUnits = deliveryOptions.deliverable_quantities.map(q => {
                    return Quantity.fromRequestValue(q.minimum_deliverable_quantity).units;
                });
                const selectableQuantitiesByUnits = deliveryOptions.deliverable_quantities.map(q => {
                    const minimumQuantity = Quantity.fromRequestValue(q.minimum_deliverable_quantity);
                    const maximumQuantity = Quantity.fromRequestValue(q.maximum_deliverable_quantity);
                    const selectableQuantities = Array.from(Array(maximumQuantity.value.value + 1).keys()).slice(minimumQuantity.value.value).map(v => {
                        return new Quantity(minimumQuantity.units, new QuantityValue(minimumQuantity.units.specification, v));
                    });
                    let result = {};
                    result[minimumQuantity.units.identifier] = selectableQuantities;
                    return result;
                }).reduce((result, next) => {
                    return Object.assign(result, next);
                }, {});
                const initialQuantity = selectableQuantitiesByUnits[selectableUnits[0].identifier][0];
                this.setState({ selectableQuantitiesByUnits: selectableQuantitiesByUnits, selectableUnits: selectableUnits, selectedQuantity: initialQuantity, selectedUnits: initialQuantity.units });
            }
        }
    }

    didChooseQuantity() {
        const quantity = this.state.selectedQuantity;
        this.props.onChooseQuantity(quantity);
    }

    didSelectQuantityChip() {
        this.props.onEditQuantity();
    }

    didUpdateSelectedQuantity(event) {
        const key = event.target.value;
        const selectableQuantities = this.state.selectableQuantitiesByUnits[this.state.selectedUnits.identifier];
        const quantity = selectableQuantities.find(q => `${q.units.identifier}-${q.value.value}` === key) || selectableQuantities[0];
        this.setState({ selectedQuantity: quantity });
    }

    didUpdateSelectedUnits(event) {
        const unitsIdentifier = event.target.value;
        const selectedUnits = this.state.selectableUnits.find(u => u.identifier === unitsIdentifier);
        if (selectedUnits !== this.state.selectedUnits) {
            let selectedQuantity = this.state.selectedQuantity;
            if (selectedQuantity) {
                const selectableQuantities = this.state.selectableQuantitiesByUnits[selectedUnits.identifier];
                selectedQuantity = selectableQuantities.find(q => q.value.value === selectedQuantity.value.value) || selectableQuantities[0];
            }
            this.setState({ selectedUnits: selectedUnits, selectedQuantity: selectedQuantity });
        }
    }

    render() {
        const deliveryOptions = this.props.deliveryOptions;
        const quantity = this.props.quantity;
        const stepNumber = this.props.stepNumber;
        const stepDescription = (quantity) ? SELECTED_STEP_DESCRIPTION : STEP_DESCRIPTION;
        const selectedQuantity = this.state.selectedQuantity;
        const selectedUnits = this.state.selectedUnits;
        const selectableUnits = this.state.selectableUnits;
        const selectableQuantitiesByUnits = this.state.selectableQuantitiesByUnits;
        const quantitySelector = (this.props.shouldShowQuantitySelector) ? (<QuantitySelector selectedQuantity={selectedQuantity}
                                                    selectedUnits={selectedUnits}
                                                    selectableUnits={selectableUnits}
                                                    selectableQuantitiesByUnits={selectableQuantitiesByUnits}
                                                    didUpdateSelectedQuantity={this.didUpdateSelectedQuantity}
                                                    didUpdateSelectedUnits={this.didUpdateSelectedUnits}
                                                    didChooseQuantity={this.didChooseQuantity}
                                />) : null;
        return (
            <ProductPurchaseStep completed={quantity || this.props.completed} stepNumber={stepNumber} stepDescription={stepDescription}>
                {(this.props.completed) ? (<div><Chip icon={<TerrainIcon />} onClick={this.didSelectQuantityChip} label={quantity.toString()} /> {this.props.customQuantityElement}</div>) : (
                    (deliveryOptions === null) ? <Progress /> : (<div>{quantitySelector} {this.props.customQuantityElement}</div>))}
            </ProductPurchaseStep>
        );
    }
}

export default QuantityPurchaseStep;