import moment from 'moment';
import parseDate from '../utils/parseDate.js';

class DeliveryTime {
    static displayValueForDate(date) {
        const midnightToday = moment().hour(0).minute(0).second(0).second(0).millisecond(0);
        const difference = date.diff(midnightToday, 'days');
        if (difference >= 0 && difference < 7) {
            if (date.weekday() === midnightToday.weekday()) {
                return 'Today';
            } else if ((date.weekday() === midnightToday.weekday() + 1) || (date.weekday() === 0 && midnightToday.weekday() === 6)) {
                return 'Tomorrow';
            } else {
                return date.format('dddd');
            }
        } else {
            return date.format('ddd MMM D');
        }
    }

    static displayValueForTime(walltime, withAMPM = true) {
        const isPM = (walltime.hour >= 12);
        const minute = walltime.minute;
        const hour = walltime.hour;
        const minuteDescription = (minute < 10) ? `0${minute}` : `${minute}`;
        const hourDescription = (hour % 12 === 0) ? "12" : `${hour % 12}`;
        let timeDescription = `${hourDescription}:${minuteDescription}`;
        return (withAMPM) ? `${timeDescription} ${(isPM) ? "PM" : "AM"}` : timeDescription;
    }

    static displayValueForTimeInterval(interval) {
        const endValue = DeliveryTime.displayValueForTime(interval.end);
        if ((interval.start.hour >= 12 && interval.end.hour < 12) || (interval.start.hour < 12 && interval.end.hour >= 12)) {
            const startValue = DeliveryTime.displayValueForTime(interval.start);
            return `${startValue}-${endValue}`;
        } else {
            const startValue = DeliveryTime.displayValueForTime(interval.start, false);
            return `${startValue}-${endValue}`;
        }
    }

    static displayValueForDeliveryTime(deliveryTime) {
        const date = parseDate(deliveryTime.requestedDate).hour(0).minute(0).second(0).second(0).millisecond(0);
        return `${DeliveryTime.displayValueForDate(date)} ${DeliveryTime.displayValueForTimeInterval(deliveryTime.requestedTimeInterval)}`;
    }

    static displayValueFromDelivery(delivery) {
        const startDate = parseDate(delivery.requested_time);
        const endDate = startDate.clone().add(delivery.requested_time_delivery_interval_in_minutes, "minute");
        const deliveryTime = {
            requestedDate: delivery.requested_time,
            requestedTimeInterval: {
                start: {
                    hour: startDate.hour(),
                    minute: startDate.minute()
                },
                end: {
                    hour: endDate.hour(),
                    minute: endDate.minute()
                }
            }
        };
        return DeliveryTime.displayValueForDeliveryTime(deliveryTime);
    }
}

export default DeliveryTime;