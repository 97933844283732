import React from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './styles/styles.css'
import GetFeedRequest from './api/GetFeedRequest.js';
import GuestAuthenticateRequest from './api/GuestAuthenticateRequest.js'
import HomeFeed from './HomeFeed.js';
import Map from './Map.js';
import Navigation from './Navigation.js';
import ProductPurchase from './ProductPurchase.js';
import PurchaseAddonsStep from './PurchaseAddonsStep.js';
import ResponseCache from './api/ResponseCache.js';
import Checkout from './Checkout.js';
import { withCookies } from 'react-cookie';
import RequestExecutor from './api/RequestExecutor';
import { Auth0Context } from './auth/react-auth0-wrapper';
import ProtectedComponent from './auth/ProtectedComponent.js';
import { StripeProvider } from 'react-stripe-elements';
import StripeConfiguration from './payment/StripeConfiguration.js';
import Confirmation from './Confirmation.js';
import Footer from './Footer.js';
import RegisterUserRequest from './api/RegisterUserRequest';
import makeAsyncScriptLoader from 'react-async-script';
import GetStoreContextRequest from './api/GetStoreContextRequest';

const GUEST_IDENTIFIER_COOKIE_KEY = "GuestIdentifier";
const HOME_FEED_NAME = 'home';

class MapLoaderComponent extends React.Component {
  render() {
    return <div/>;
  }
}

const WrappedMapLoaderComponent = makeAsyncScriptLoader("https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js")(MapLoaderComponent);

class App extends React.Component {
  getRouterRef;
  onNavigationSignIn;

  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    // TODO: use a session cookie to keep this value across reloads.
    this.fetchHomeFeed = this.fetchHomeFeed.bind(this);
    this.fetchStoreContext = this.fetchStoreContext.bind(this);
    this.authorize = this.authorize.bind(this);
    this.guestAuthenticationRequest = null;
    this.getRouterRef = this.getRouterRef.bind(this);
    this.showFooter = this.showFooter.bind(this);
    this.onNavigationSignIn = this.onNavigationSignIn.bind(this);
    this.state = { showFooter: true };
  }

  _fetchUserIdentifier() {
    const userIdentifier = ResponseCache.getGuestUserIdentifier();
    if (userIdentifier !== null) {
      return Promise.resolve(userIdentifier);
    } else {
      if (this.props.cookies && this.props.cookies.cookies) {
        const guestIdentifierCookieValue = this.props.cookies.cookies[GUEST_IDENTIFIER_COOKIE_KEY];
        if (guestIdentifierCookieValue && guestIdentifierCookieValue !== "") {
          this._updateGuestUserIdentifier(guestIdentifierCookieValue);
          return Promise.resolve(guestIdentifierCookieValue);
        }
      }
      return GuestAuthenticateRequest.execute()
        .then(data => {
          this._updateGuestUserIdentifier(data.identifier);
          return data.identifier;
        })
        .catch(error => {
          throw error;
        });
    }
  }

  authorize() {
    if (this.context.loading) {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(this.authorize()), 50);
      });
    }
    const getToken = (!this.context.loading && this.context.isAuthenticated && this.context.getTokenSilently) ? this.context.getTokenSilently() : Promise.resolve(null);
    return getToken
      .then(data => {
        if (data) {
          RequestExecutor.setBearerAuthorizationToken(data);
          return RegisterUserRequest.execute(data)
        } else {
          return this._fetchUserIdentifier();
        }
      })
      .then(() => this.fetchStoreContext())
      .then(data => {
        RequestExecutor.setStoreContext(data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  fetchHomeFeed() {
    return GetFeedRequest.execute(HOME_FEED_NAME);
  }

  fetchStoreContext() {
    return Promise.resolve(null);
  }

  getRouterRef(ref) {
    this.props.onCreateHistory(ref.history);
  }

  showFooter(show) {
    this.setState({ showFooter: show });
  }

  onNavigationSignIn() {
    if (!this.context.loading) {
      if (this.context.isAuthenticated) {
       this.context.logout({ returnTo: window.location.origin })
      } else {    
        this.context.loginWithRedirect({
          appState: { targetUrl: "/" }
        });
      }
    }
  }

  render() {
    return (
      <StripeProvider apiKey={StripeConfiguration.apiKey}>
        <Router ref={this.getRouterRef}>
          <div className="siteContent">
            <WrappedMapLoaderComponent asyncScriptOnLoad={() => Map.initialize()} />
            <div className="mainContent">
              <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1" name="viewport" />
                <title>Rockery | Rock, sand and gravel delivery to your job site.</title>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link href="https://fonts.googleapis.com/css?family=Montserrat:800&display=swap" rel="stylesheet" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="shortcut icon" href="/favicon.png" />
                <meta content="Rock, sand and gravel delivered directly to your job site with scheduled delivery, partial loads, and free same-day service." name="description" />
                <meta content="Rockery | Rock, sand and gravel delivery to your job site." property="og:title" />
                <meta content="Rock, sand and gravel delivered directly to your job site with scheduled delivery, partial loads, and free same-day service." property="og:description" />
              </Helmet>
              <Navigation onSignIn={this.onNavigationSignIn} isLoadingAuthentication={this.context.loading} isAuthenticated={!this.context.loading && this.context.isAuthenticated} />
              <Route exact path="/" render={props => <HomeFeed {...props} fetchHomeFeed={this.fetchHomeFeed} authorize={this.authorize} showFooter={this.showFooter} />} />
              <Route path="/products" render={props => <ProductPurchase {...props} authorize={this.authorize} showFooter={this.showFooter} fetchStoreContext={this.fetchStoreContext} />} />
              <Route path="/checkout" render={props => <ProtectedComponent {...props} component={Checkout} authorize={this.authorize} showFooter={this.showFooter} />} />
              <Route path="/confirmation" render={props => <ProtectedComponent {...props} component={Confirmation} authorize={this.authorize} showFooter={this.showFooter} />} />
              <Route path="/addons" render={props => <PurchaseAddonsStep {...props} authorize={this.authorize} showFooter={this.showFooter} />} />
            </div>
            {(this.state.showFooter) ? <Footer /> : null}
          </div>
        </Router>
      </StripeProvider>
    );
  }

  _updateGuestUserIdentifier(userIdentifier) {
    RequestExecutor.setGuestAuthorizationToken(userIdentifier);
    ResponseCache.setGuestUserIdentifier(userIdentifier);
  }
}

export default withCookies(App);
