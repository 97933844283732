import RequestExecutor from './RequestExecutor.js';

const UPDATE_ORDER_PRODUCT_ADDONS_ENDPOINT = `/orders/saved/addons`;

class UpdateOrderProductAddonsRequest {
    static execute(selections) {
        const query = { products: Object.keys(selections).map((key) => ({ product_identifier: key, quantity: selections[key].quantity.toRequestValue() })) };
        return RequestExecutor.executePost(UPDATE_ORDER_PRODUCT_ADDONS_ENDPOINT, query);
    }
}

export default UpdateOrderProductAddonsRequest;