const SCALAR_VALUE = "scalar";
const DECIMAL_VALUE = "decimal";
const REAL_VALUE = "real";

class UnitsSpecification {
    static get scalar() {
        return SCALAR_VALUE;
    }

    static get decimal() {
        return DECIMAL_VALUE;
    }

    static get real() {
        return REAL_VALUE;
    }

    static get allTypes() {
        return [UnitsSpecification.scalar, UnitsSpecification.decimal, UnitsSpecification.real];
    }

    value;
    constructor(value) {
        if (!value || !UnitsSpecification.allTypes.includes(value)) {
            throw new Error("Invalid specification.");
        }
        this.value = value;
    }

    static isSpecification(spec) {
        return spec && UnitsSpecification.allTypes.includes(spec.value);
    }

    isValidValue(value) {
        if (typeof value !== "number") {
            return false;
        }
        switch (this.value) {
            case UnitsSpecification.scalar:
                return value % 1 === 0;
            case UnitsSpecification.real:
            case UnitsSpecification.decimal:
                return true;
            default:
                throw new Error("Invalid operation.");
        }
    }
}

class DisplayName {
    singlular;
    plural;

    constructor(singular, plural) {
        if (!singular || singular === "") {
            throw new Error("A singular value is required.");
        }
        if (!plural || plural === "") {
            throw new Error("A plural value is required.");
        }
        this.singular = singular;
        this.plural = plural;
    }

    static isDisplayName(value) {
        return value && value.singular && value.singular !== "" && value.plural && value.plural !== "";
    }
}

class Units {
    identifier;
    displayName;
    specification;

    constructor(identifier, displayName, specification) {
        if (!identifier || identifier === "") {
            throw new Error("An identifier is required.");
        }
        if (!displayName || !DisplayName.isDisplayName(displayName)) {
            throw new Error("A display name is required.");
        }
        if (!specification || !UnitsSpecification.isSpecification(specification)) {
            throw new Error("A specification is required.");
        }
        this.identifier = identifier;
        this.displayName = displayName;
        this.specification = specification;
    }

    static isUnits(units) {
        return (
            units.identifier &&
            units.identifier !== "" &&
            DisplayName.isDisplayName(units.displayName) &&
            UnitsSpecification.isSpecification(units.specification)
        );
    }

    static fromRequestValue(units) {
        const specification = new UnitsSpecification(units.specification.type);
        return new Units(units.identifier, units.display_name, specification);
    }

    perUnitDisplayName() {
        return `PER ${this.displayName.singular.toUpperCase()}`;
    }
}

class QuantityValue {
    specification;
    value;

    constructor(specification, value) {
        if (!specification || !UnitsSpecification.isSpecification(specification)) {
            throw new Error("Invalid specification.");
        }
        if ((value === null || typeof value === "undefined") || !specification.isValidValue(value)) {
            throw new Error("Invalid value.");
        }
        this.specification = specification;
        this.value = value;
    }

    static isQuantityValue(value) {
        return value && UnitsSpecification.isSpecification(value.specification) && (value.value !== null && typeof value.value !== "undefined") && value.specification.isValidValue(value.value);
    }
}

class Quantity {
    units;
    value;

    constructor(units, value) {
        if (!units || !Units.isUnits(units)) {
            throw new Error("Invalid units.");
        }
        if ((value === null || typeof value === "undefined") || !QuantityValue.isQuantityValue(value)) {
            throw new Error("Invalid value.");
        }
        if (value.specification.value !== units.specification.value) {
            throw new Error("Value does not match units specification.");
        }
        this.units = units;
        this.value = value;
    }

    static fromRequestValue(quantity) {
        const specification = new UnitsSpecification(quantity.value.type);
        const units = new Units(quantity.units.identifier, quantity.units.display_name, specification);
        const value = new QuantityValue(specification, quantity.value.value);
        return new Quantity(units, value);
    }

    static isQuantity(quantity) {
        return quantity && quantity.units && Units.isUnits(quantity.units) && quantity.value && QuantityValue.isQuantityValue(quantity.value);
    }

    static inUnits(units, value) {
        return new Quantity(units, new QuantityValue(units.specification, value));
    }

    compareTo(quantity) {
        if (!quantity || !Quantity.isQuantity(quantity) || this.units.specification.value !== quantity.units.specification.value) {
            throw new Error("Invalid comparison.");
        }
        if (Math.abs(this.value.value - quantity.value.value) < Number.EPSILON) {
            return 0;
        }
        return Math.sign(this.value.value - quantity.value.value);
    }

    isEqualTo(quantity) {
        return this.compareTo(quantity) === 0;
    }

    isLessThan(quantity) {
        return this.compareTo(quantity) < 0;
    }

    isGreaterThan(quantity) {
        return this.compareTo(quantity) > 0;
    }

    toString() {
        const value = new Intl.NumberFormat(window.navigator.language, { maximumSignificantDigits: 2 }).format(this.value.value);
        const unitsValue = (value > 0 && value < 2) ? this.units.displayName.singular : this.units.displayName.plural;
        return `${value} ${unitsValue}`;
    }

    toRequestValue() {
        const units_identifier = this.units.identifier;
        const value = this.value.value;
        switch (this.units.specification.value) {
            case UnitsSpecification.scalar:
                return {
                    units_identifier: units_identifier,
                    scalar_value: value
                };
            case UnitsSpecification.real:
                return {
                    units_identifier: units_identifier,
                    real_value: value
                };
            case UnitsSpecification.decimal:
                return {
                    units_identifier: units_identifier,
                    real_value: value
                };
            default:
                throw new Error("Invalid quantity.");
        }
    }
}

export { DisplayName, Units, UnitsSpecification, Quantity, QuantityValue };
export default Quantity;