import React from 'react';
import Container from 'react-bulma-components/lib/components/container';

class ProductPurchaseStep extends React.Component {
    componentDidMount() {
        const stepNumber = this.props.stepNumber;
        const headerId = `productPurchaseStepContainer-${stepNumber}`;
        const header = document.getElementById(headerId);
        if (header) {
            header.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        const children = this.props.children;
        const stepNumber = this.props.stepNumber;
        const stepDescription = this.props.stepDescription;
        const stepNumberClassName = `productPurchaseStepNumber${(this.props.completed) ? " completedStep" : ""}`;
        const headerId = `productPurchaseStepContainer-${stepNumber}`;
        return (
            <Container className="productPurchaseStepContainer" id={headerId}>
                <div className="productPurchaseStepHeader">
                    <h2 className={stepNumberClassName}>{stepNumber}</h2>
                    <h2 className="productPurchaseStepDescription">{stepDescription}</h2>
                </div>
                <div className="productPurchaseStepContent">
                    {children}
                </div>
            </Container>
        );
    }
}

export default ProductPurchaseStep;