const PRODUCT_IDENTIFIER_PREFIX = "product-";
const GUEST_USER_IDENTIFIER_KEY = "guestUserIdentifier";
const HOME_FEED_ITEMS_RESPONSE_KEY = "homeFeedItems";
const CACHE_ENTRY_TTL_IN_MS = 30 /* minutes */ * 60 /* seconds */ * 1000 /* milliseconds */

class ResponseCache {
    static setGuestUserIdentifier(identifier) {
        if (identifier === null) {
            delete ResponseCache._cache[GUEST_USER_IDENTIFIER_KEY];
            return null;
        }
        ResponseCache._cache[GUEST_USER_IDENTIFIER_KEY] = {dateInserted: new Date(), identifier: identifier};
        return identifier;
    }

    static getGuestUserIdentifier() {
        const result = ResponseCache._cache[GUEST_USER_IDENTIFIER_KEY];
        if (result === undefined || result === null) {
            return null;
        }
        if (Math.abs(result.dateInserted.getTime() - Date.now()) > CACHE_ENTRY_TTL_IN_MS) {
            ResponseCache.setGuestUserIdentifier(null);
            return null;
        }
        return result.identifier;
    }

    static setHomeFeedItemsResponse(feedItems) {
        if (feedItems === null) {
            delete ResponseCache._cache[HOME_FEED_ITEMS_RESPONSE_KEY];
            return null;
        }
        ResponseCache._cache[HOME_FEED_ITEMS_RESPONSE_KEY] = {dateInserted: new Date(), feedItems: feedItems};
    }

    static getHomeFeedItemsResponse() {
        const result = ResponseCache._cache[HOME_FEED_ITEMS_RESPONSE_KEY];
        if (result === undefined || result === null) {
            return null;
        }
        if (Math.abs(result.dateInserted.getTime() - Date.now()) > CACHE_ENTRY_TTL_IN_MS) {
            ResponseCache.setHomeFeedItemsResponse(null);
            return null;
        }
        return result.feedItems;
    }

    static getByProductIdentifier(identifier) {
        const result = ResponseCache._cache[`${PRODUCT_IDENTIFIER_PREFIX}-${identifier}`];
        if (result === undefined || result === null) {
            return null;
        }
        if (Math.abs(result.dateInserted.getTime() - Date.now()) > CACHE_ENTRY_TTL_IN_MS) {
            ResponseCache.setByProductIdentifier(identifier, null);
            return null;
        }
        return result.product;
    }

    static setByProductIdentifier(identifier, product) {
        const key = `${PRODUCT_IDENTIFIER_PREFIX}-${identifier}`;
        if (product === null) {
            delete ResponseCache._cache[key];
            return null;
        }
        if (product.identifier !== identifier && product.slug !== identifier) {
            throw new Error("Invalid operation.");
        }
        const item = {dateInserted: new Date(), product: product}
        ResponseCache._cache[key] = item;
        return item;
    }
}

ResponseCache._cache = {};

export default ResponseCache;